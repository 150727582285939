class Messages {
    ERROR_EMPTY_CREDENTIALS = "User credentials should not be empty!"
    ERROR_DATE_COHERENCY = "Start date should be before end date"
    ERROR_OUTDATE = "Start date is outdated"
    ERROR_DATES_VALIDATION = "Invalid dates"
    ERROR_BATCH_FORMAT_MSISDN = "Invalid msisdn format in attached file"
    ERROR_CSV_TEMPLATE = "Error attached file exception"
    ERROR_CSV_COLUMNS = "Invalid columns on the attached file"
    ERROR_VALIDATION_CSV_FORMAT = "Invalid format of the attached file"
    SUCCESS_SCHEDULING_MESSAGE = "Task has been scheduled successfully"
    NO_ACCESS_PRIVILEGES_MESSAGE = "You don't have access privileges, please contact your administrator"
}
export default new Messages();