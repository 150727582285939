import React from 'react';
import { Dialog } from 'primereact/dialog'

export const Popup = (props) => {


    return (
        <Dialog
            modal
            header={props.title}
            visible={props.visible}
            dismissableMask={props.dismissable}
            onHide={props.onClose}
            style={{ width: props.size}}
            contentClassName="custom-dialog"
            className={props.className}>
            <div className="mb-4">
                {props.content}
            </div>
            <div className="footer">
                {props.footer}
            </div>
        </Dialog>
    );
}