import React from "react";

export const Alert = (props) => {
  return (
    <div
      className={`alert alert-${props.type} my-2 d-flex justify-content-center align-items-center`}
    >
      <i className={`${props.icon} mr-1`} /> {props.content}
    </div>
  );
};
