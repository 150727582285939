import React from 'react';

export const Button = (props) => {
    return (
        <div className="form-group m-0" >
            <button
                type={props.type}
                className={`btn btn-primary btn-block ${props.className ? props.className+"  custom-btn" : "custom-btn"}`}
                disabled={props.disabled}
                icon="pi pi-check"
                iconPos="right"
                title={props.title}
                onClick={props.onClick}
                style={{ borderRadius: ` ${props.rounded && '50px'}` }}>
                {props.label}
            </button>
        </div>
    );
}