import React, { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import { useHistory } from "react-router";
import { BASE_ROUTES, NAME_SLIDE_PATH } from "../../utils";
import { Spinner } from "../shared";
import { Button } from "primereact/button";

export const SlideSupplierLeft = (props) => {
  const [action, setAction] = useState(BASE_ROUTES.PURCHARSE_ORDERS.path);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(false);
    history.push(action);
  }, [update]);

  useEffect(() => {
    props.slideContainerLeft(show);
  }, [show]);

  const isActiveLink = (route) => {
    return history.location.pathname.includes(route);
  };
  const change = (actio) => {
    setLoading(true);
    setAction(actio);
    setUpdate(!update);
  };
  const dropdownItemsDec = [
    {
      label: BASE_ROUTES.PURCHARSE_ORDERS.label,
      action: BASE_ROUTES.PURCHARSE_ORDERS.path,
      icon: "fa fa-certificate",
      className:
        isActiveLink(BASE_ROUTES.PURCHARSE_ORDERS.path) && "active-link-act",
    },
    // {
    //     label: BASE_ROUTES.PURCHARSE_EDIT.label,
    //     action: BASE_ROUTES.PURCHARSE_EDIT.path,
    //     icon: "fa fa-cogs",
    //     className: isActiveLink(BASE_ROUTES.PURCHARSE_EDIT.path) && "active-link-act"
    // },
  ];
  const slideContainerLeft = () => {
    setShow(!show);
  };
  return (
    <div className="container-left">
      {!loading && (
        <>
          <div className="titre-bar-slide">
            {show && (
              <>
                <i className={`i-bar-nav-menu fa fa-cubes`}></i>
                <span>{NAME_SLIDE_PATH.SUPPLIER_NAME_TITRE.name} Part</span>
              </>
            )}
            <Button
              className={`p-button-icon-cs ${
                show ? "cs-icon-light" : "cs-icon-light-none"
              }`}
              type="button"
              title="Slide"
              onClick={slideContainerLeft}
            >
              <i
                className={`cs-icon-fa fa ${show ? "fa-outdent" : "fa-indent"}`}
              ></i>
            </Button>
          </div>
          <br></br>
          <div className="bar-nav-menu">
            {dropdownItemsDec.map((sweetItem) => {
              return (
                <button
                  type="button"
                  className={`btn  btn-bar-nav-menu btn-outline-secondary justify-content-start ts-bt ${sweetItem.className} `}
                  onClick={() => change(sweetItem.action)}
                  title={sweetItem.label}
                >
                  <i className={`i-bar-nav-menu ${sweetItem.icon}`}></i>
                  {show && sweetItem.label}
                </button>
              );
            })}
          </div>
        </>
      )}
      {loading && <Spinner />}
    </div>
  );
};
