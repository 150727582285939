import React, { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import { useHistory } from "react-router";
import { Spinner } from "../shared";
import utils,{ BASE_ROUTES, NAME_SLIDE_PATH } from "../../utils";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

export const SlideManagerLeft = (props) => {
  const [action, setAction] = useState(BASE_ROUTES.SUPPLIERS_LIST.path);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    history.push(action);
    setLoading(false);
  }, [update]);

  useEffect(() => {
    props.slideContainerLeft(show);
  }, [show]);

  const isActiveLink = (route) => {
    return history.location.pathname.includes(route);
  };

  const change = (actio) => {
    setLoading(true);
    setAction(actio);
    setUpdate(!update);
  };
  const dropdownItemsDec = [
    {
      label: BASE_ROUTES.SUPPLIERS_LIST.label,
      action: BASE_ROUTES.SUPPLIERS_LIST.path,
      icon: "fa fa-list-ul",
      role: utils.rolesList ,
      className:
        isActiveLink(BASE_ROUTES.SUPPLIERS_LIST.path) && "active-link-act",
    },
    {
      label: BASE_ROUTES.SUPPLIERS_USER.label,
      action: BASE_ROUTES.SUPPLIERS_USER.path,
      icon: "pi pi-users",
      role:utils.rolesListDegital,
      className:
        isActiveLink(BASE_ROUTES.SUPPLIERS_USER.path) && "active-link-act",
    },
    {
      label: BASE_ROUTES.SUPPLIERS_REGECTION.label,
      action: BASE_ROUTES.SUPPLIERS_REGECTION.path,
      icon: "pi pi-th-large",
      role:utils.rolesListDegital,
      className:
        isActiveLink(BASE_ROUTES.SUPPLIERS_REGECTION.path) && "active-link-act",
    },
  ];
  const slideContainerLeft = () => {
    setShow(!show);
  };
  return (
    <div className="container-left">
      {!loading && (
        <>
          <div className="titre-bar-slide">
            {show && (
              <>
                <i className={`i-bar-nav-menu fa fa-home`}></i>
                <span>{NAME_SLIDE_PATH.OA_NAME_TITRE.name} Part</span>
              </>
            )}
            <Button
              className={`p-button-icon-cs ${
                show ? "cs-icon-light" : "cs-icon-light-none"
              }`}
              type="button"
              title="Slide"
              onClick={slideContainerLeft}
            >
              <i
                className={`cs-icon-fa fa ${show ? "fa-outdent" : "fa-indent"}`}
              ></i>
            </Button>
          </div>
          <Divider className="div-sep-css-spr" layout="vertical" />
          <div className="bar-nav-menu">
            {dropdownItemsDec.map((sweetItem) => {
              if (utils.isUserPriviliged(sweetItem.role)) {
                return <button type="button"
                               className={`btn  btn-bar-nav-menu btn-outline-secondary justify-content-start ts-bt ${sweetItem.className} `}
                               onClick={() => change(sweetItem.action)}
                               title={sweetItem.label}
                >
                  <i className={`i-bar-nav-menu ${sweetItem.icon}`}></i>
                  {show && sweetItem.label}</button>
              }
            })}
          </div>
        </>
      )}
      {loading && <Spinner />}
    </div>
  );
};
