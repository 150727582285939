import { HttpService } from ".";
const API_MANAGER = process.env.REACT_APP_API_URL_MANAGER;

class UserService {
  login = ({ username, password }) => {
    return HttpService.post("/login", { username, password });
  };

  logout = (token) => {
    return HttpService.get(`/user/api/v1/logout`);
  };

  resetPassword = (data) => {
    return HttpService.post("/user/api/v1/reset-password", data);
  };

  getListUsers = () => {
    return HttpService.get(`/user/api/v1/users`);
  };

  deleteUser = (id) => {
    return HttpService.patch(`/user/api/v1/user/` + id);
  };

  // postUser=(data)=>{
  //     return HttpService.post(API_MANAGER+'/api/v1/user',data)
  // }
}

export default new UserService();
