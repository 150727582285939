import {Button} from "primereact/button";
import React from "react";

export const ActionReturn= (props) => {

    return (
        <Button className={'p-button-icon-cs '+props.className}
                style={props.style}
                type="button"
                title="Return"
                onClick={props.onClick}
        >
            <i className="fa fa-reply-all"></i>
        </Button>
    )
}