import React from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import $ from "jquery";
import { Avatar } from "primereact/avatar";

export const ProfileDropdownMenu = (props) => {
  const DropdownItem = (props) => {
    return (
      <span
        className={`dropdown-item ${props.className}`}
        style={{ cursor: "pointer", fontSize: "14px" }}
        onClick={props.action}
      >
        <i className={`mr-2 ${props.icon}`} style={{ fontSize: "14px" }} />
        {props.label}
      </span>
    );
  };

  const HeaderTemplate = () => {
    const { label, subLabel } = props.header;
    return (
      <>
        <div className="d-flex mx-3">
          <Avatar
            label={props.label}
            icon="pi pi-user"
            image={props.image}
            size={props.size ? props.size : "small"}
            className={props.className}
            shape={props.shape ? props.shape : "circle"}
            style={{ borderRadius: "50px" }}
          />
          <div style={{ display: "grid" }}>
            <span
              className="ml-2 font-weight-bol text-uppercase"
              style={{ whiteSpace: "nowrap" }}
            >
              {label}
            </span>
            <small className="ml-2">{subLabel}</small>
          </div>
        </div>
        <div className="dropdown-divider"></div>
      </>
    );
  };

  $(document).ready(function () {
    $(".dropdown-menu").css(
      "margin-left",
      `-${$(".dropdown-menu").width() * 0.7}px`
    );
  });

  return (
    <div className="nav-item dropdown">
      <span
        className="nav-link"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {" "}
        {props.toggleElement}
      </span>
      <div className="dropdown-menu">
        {props.header && <HeaderTemplate />}
        {props.items.map((item, key) => {
          return (
            <DropdownItem
              key={key}
              label={item.label}
              icon={item.icon}
              action={item.action}
              className={item.className}
            />
          );
        })}
        {props.footer && (
          <>
            <div className="dropdown-divider"></div>
            <DropdownItem
              label={props.footer.label}
              icon={props.footer.icon}
              action={props.footer.action}
            />
          </>
        )}
      </div>
    </div>
  );
};
