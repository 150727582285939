import React, { useRef, useState, useEffect } from "react";
import { CustomDataTable, ToastNotification } from "../../shared";
import { SuppliersService } from "../../../api";
import Outils from "./outils";
import { useHistory } from "react-router";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { ActionClear } from "../commun/action-clear";

export const PurchaseOrders = () => {
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState();
  const [name, setName] = useState();
  const [lazyParams, setLazyParams] = useState(Outils._getLazyParams());
  const [filterParams, setFilterParams] = useState(
    JSON.parse(sessionStorage.getItem("purchase-filters")) != undefined
      ? JSON.parse(sessionStorage.getItem("purchase-filters"))
      : Outils._getParamFilter()
  );
  const [clear, setClear] = useState(true);
  const toastRef = useRef();
  const dt = useRef(null);
  const history = useHistory();

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, filterParams]);

  const loadLazyData = () => {
    setLoading(true);
    SuppliersService.purchaseOrders(
      lazyParams.page,
      lazyParams.rows,
      filterParams
    ).then(
      (d) => {
        setName(
          d.data.elements
            ? d.data.elements.length > 0
              ? ", Name: " + d.data.elements[0].supplierName
              : ""
            : ""
        );
        setData(Outils._map(d.data.elements, history));
        setTotalRecords(d.data.totalElement);
        setLoading(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };
  const onPage = (event) => {
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };
  function _handleChange(e) {
    setFilterParams((prevState) => {
      sessionStorage.setItem(
        "purchase-filters",
        JSON.stringify({ ...prevState, [e.target.name]: e.target.value })
      );
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  function _handleDateChange(e) {
    setFilterParams((prevState) => {
      return {
        ...prevState,
        [e.target.name]: new Date(e.target.value).getTime(),
      };
    });
  }
  const _clearFilter = () => {
    setFilterParams(Outils._getParamFilter());
    setClear(true);
    sessionStorage.removeItem("purchase-filters");
  };
  const _applyFilter = () => {
    setClear(false);
  };
  const onFilter = (event) => {
    event["first"] = 0;
    event["rows"] = 10;
    event["page"] = 0;
    setLazyParams(event);
  };
  const _getfilterContainer = () => {
    return (
      <div className=" container justify-content-center">
        <div className="row">
          <div className="col-sm-1 filter-element">
            <span className="p-float-label">
              <InputText
                type="number"
                id="poNumber"
                className="p-filter-tab-cs"
                name="poNumber"
                value={filterParams.poNumber}
                onChange={_handleChange}
              />
              <label htmlFor="poNumber">PO Nbr</label>
            </span>
          </div>
          <div className="col-sm-3 filter-element">
            <span className="p-float-label">
              <InputText
                id="poDescription"
                className="p-filter-tab-cs"
                name="poDescription"
                value={filterParams.poDescription}
                onChange={_handleChange}
              />
              <label htmlFor="poDescription">Description</label>
            </span>
          </div>
          <div className="col filter-element">
            <span className="p-float-label">
              <Calendar
                id="poDate"
                className="p-filter-tab-cs"
                name="poDate"
                monthNavigator
                yearNavigator
                type="Date"
                yearRange="2010:2200"
                value={filterParams.poDate}
                onChange={_handleDateChange}
              />
              <label htmlFor="poDate">PO Date</label>
            </span>
          </div>
          <div className="col filter-element">
            <span className="p-float-label">
              <Calendar
                id="poApprovedDate"
                className="p-filter-tab-cs date-cv"
                name="poApprovedDate"
                type="Date"
                monthNavigator
                yearNavigator
                yearRange="2010:2200"
                value={filterParams.poApprovedDate}
                onChange={_handleDateChange}
              />
              <label htmlFor="poApprovedDate">Approved Date</label>
            </span>
          </div>
          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                id="authorization"
                name="authorization"
                className="p-filter-tab-cs"
                value={filterParams.authorization}
                onChange={_handleChange}
              />
              <label htmlFor="authorization">Authorization</label>
            </span>
          </div>
          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                id="poCurrencyCode"
                name="poCurrencyCode"
                className="p-filter-tab-cs"
                value={filterParams.poCurrencyCode}
                onChange={_handleChange}
              />
              <label htmlFor="poCurrencyCode">Currency Code</label>
            </span>
          </div>
          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                type="number"
                id="poAmount"
                className="p-filter-tab-cs "
                name="poAmount"
                value={filterParams.poAmount}
                onChange={_handleChange}
              />
              <label htmlFor="poAmount">Amount</label>
            </span>
          </div>
          <div className="filter-element-btn mt-1">
            <ActionClear onClick={_clearFilter} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {data && data[0]?.poCurrencyCode === "DZD" && (
        <marquee>
          Chers Fournisseurs , la date de paiement reprise sur la plateforme est
          la date du lancement du paiement .Reste à considérer également la
          signature du paiement ainsi que le traitement de la banque .
        </marquee>
      )}

      <CustomDataTable
        title={`Purchase orders${name}`}
        icon="pi bi bi-person-lines-fill"
        data={data}
        lazy={true}
        totalRecords={totalRecords}
        loading={loading}
        ref={dt}
        onPage={onPage}
        onSort={onSort}
        onFilter={onFilter}
        filterContainer={_getfilterContainer()}
        clear={clear}
        first={lazyParams.first}
        page={lazyParams.page}
        rows={lazyParams.rows}
        columns={Outils._getColumns()}
        className="table-large"
      />
      <ToastNotification ref={toastRef} />
    </>
  );
};
