import React, { useRef, useState, useEffect } from "react";
import { CustomDataTable, Popup, ToastNotification } from "../../shared";
import { ManagerService } from "../../../api";
import Outils from "./outils";
import { useHistory } from "react-router";
import { NewRejectionForm } from "./new-rejection-form";
import { InputText } from "primereact/inputtext";

export const RejectionsList = () => {
  const [loading, setLoading] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState(Outils._getLazyParams());
  const [filterParams, setFilterParams] = useState();
  const [data, setData] = useState();
  const toastRef = useRef();
  const dt = useRef(null);
  const history = useHistory();

  useEffect(() => {
    loadLazyData();
  }, []);

  const loadLazyData = () => {
    setLoading(true);
    ManagerService.getListRejections().then(
      (data) => {
        setData(Outils._mapRegections(data.data, deleteRejection));
        setTotalRecords(data?.data?.length);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
      }
    );
  };
  function _handleChange(e) {
    setFilterParams((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }
  const onPage = (event) => {
    setLazyParams(event);
  };
  const _add = () => {
    setAddNew(true);
  };
  const _hide = () => {
    setAddNew(false);
    loadLazyData();
  };
  const _filterElement = (field) => {
    switch (field) {
      case "action":
        return <></>;
      default:
        return (
          <div className="col filter-element">
            <InputText
              id={field}
              className="p-filter-tab-cs"
              name={field}
              onChange={() => {}}
            />
          </div>
        );
    }
  };

  const deleteRejection = (id) => {
    setLoading(true);
    ManagerService.deleteRejection(id).then(
      (data) => {
        setLoading(false);
        toastRef?.current?.showToast({
          type: "success",
          title: "Success response",
          message: data.message,
          durable: false,
        });
        loadLazyData();
      },
      (error) => {
        setLoading(false);
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
      }
    );
  };

  return (
    <>
      <CustomDataTable
        title="Rejections"
        icon="pi bi bi-person-lines-fill"
        data={data}
        totalRecords={totalRecords}
        loading={loading}
        ref={dt}
        onPage={onPage}
        first={lazyParams.first}
        page={lazyParams.page}
        rows={lazyParams.rows}
        columns={Outils._getColumnsRegections()}
        new={_add}
        filterElement={_filterElement}
        className="table-large"
      />
      <Popup
        title={"Add new rejection"}
        visible={addNew}
        maximizable
        className="custom-dialog-small"
        content={<NewRejectionForm onClose={_hide} />}
        onClose={_hide}
      ></Popup>
      {!loading && <ToastNotification ref={toastRef} />}
    </>
  );
};
