import React, { useRef, useState, useEffect } from "react";
import { ToastNotification, InpuText, Spinner } from "../../shared";
import { useFormik } from "formik";
import { ManagerService } from "../../../api";
import { Button } from "primereact/button";

export const NewRejectionForm = (props) => {
  const toastRef = useRef();
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    formik.resetForm({ rejectionName: "" });
  }, []);

  const formik = useFormik({
    initialValues: { rejectionName: "" },
    validate: (data) => {
      let errors = {};
      if (!data.rejectionName) {
        errors.rejectionName = "this field is required.";
      }
      return errors;
    },
    onSubmit: (data) => {
      _handleSubmit(data);

      setShowMessage(true);
    },
  });
  const _handleSubmit = (data) => {
    setLoading(true);
    ManagerService.postRejection(data).then(
      (datac) => {
        toastRef?.current?.showToast({
          type: "success",
          title: "Success response",
          message: datac.message,
          durable: false,
        });
        setLoading(false);
        props.onClose();
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };

  const isFormFieldValid = (name) => {
    return !!(formik.touched[name] && formik.errors[name]);
  };

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-demo">
            <div className="p-d-flex p-jc-center">
              <div className="p-fluid">
                <InpuText
                  id="rejectionName"
                  name="rejectionName"
                  label="Name"
                  value={formik.values.rejectionName}
                  onChange={formik.handleChange}
                  iconRight="fa fa-retweet"
                  required
                  autoFocus
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />

                <div className="mt-3">
                  <Button type="submit" label="Submit" loading={loading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
      <ToastNotification ref={toastRef} />
    </>
  );
};
