import React, { useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import { Dropdown } from "primereact/dropdown";

export const DropdownInput = (props) => {

    const emptyFilterMessage = () => {
        return (
                <small className="p-error">No results found</small>
        );
    };

  return (
    <div className="p-float-inpuText">
      <span className={"p-float-label p-input-icon-right"}>
        <i className={props.iconRight} />
        <Dropdown
          id={props.id}
          placeholder={props.placeholder}
          name={props.name}
          disabled={props.disabled}
          onChange={props.onChange}
          optionLabel={props.optionLabel}
          optionValue={props.optionValue}
          value={props.value}
          virtualScrollerOptions={props.virtualScrollerOptions}
          filter={props.filter}
          onFilter={props.onFilter}
          valueTemplate={props.valueTemplate}
          itemTemplate={props.itemTemplate}
          options={props.options}
          emptyFilterMessage={emptyFilterMessage}
          emptyMessage="No results found"
          className={
            props.isFormFieldValid(props.name)
              ? "p-error p-inputtext-size "
              : "p-valid p-inputtext-size"
          }
        />
        <label htmlFor={props.id}>
          {props.label}
          {props.required ? "*" : ""}
        </label>
      </span>
      <div className="p-float-inpuText-erreur">
        {props.getFormErrorMessage(props.name)}
      </div>
    </div>
  );
};
