import React, { useEffect, useState } from "react";
import { Header } from "/";
import { SlideSupplierLeft } from "./slide-supplier-left";
import { SlideManagerLeft } from "./slide-manager-left";
import { NAME_SLIDE_PATH } from "../../utils";
import { Spinner } from "../shared";
import { ScrollTop } from "primereact/scrolltop";

export const RootContainer = (props) => {
  const [targetSider, setTargetSider] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSize, setLoadingSize] = useState(false);
  const [size, setSize] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    setLoading(false);
    setLoadingSize(false);
  }, [targetSider]);

  useEffect(() => {
    setAccessDenied(props.children?.type?.name === "AccessDenied");
  }, [props.children]);

  const pathSlideContainer = (slide) => {
    setAccessDenied(false);
    setLoading(true);
    switch (slide) {
      case NAME_SLIDE_PATH.SUPPLIER_NAME_TITRE.label:
        setTargetSider(
          <SlideSupplierLeft slideContainerLeft={slideContainerLeft} />
        );
        break;
      case NAME_SLIDE_PATH.OA_NAME_TITRE.label:
        setTargetSider(
          <SlideManagerLeft slideContainerLeft={slideContainerLeft} />
        );
        break;
      default:
        setTargetSider(<div className="mx-3">No content !</div>);
        break;
    }
  };
  useEffect(() => {}, [size]);

  const slideContainerLeft = (show) => {
    setSize(show);
  };

  return (
    <div>
      <Header pathSlideContainer={pathSlideContainer} />
      {!loading && (
        <div className="container-global">
          {!accessDenied && (
            <>
              <div
                className={`container ${
                  !size ? "container_hide" : "container_show"
                }`}
              >
                {targetSider}
              </div>
              <div
                className={`container ${
                  !size ? "container_hide_right" : "container_show_right"
                }`}
              >
                <div className="container-right">{props.children}</div>
              </div>
            </>
          )}
          {accessDenied && (
            <div className={`container`}>
              <div className="container-right">{props.children}</div>
            </div>
          )}
          {loading && <Spinner />}
        </div>
      )}

      <ScrollTop
        threshold={100}
        className="custom-scrolltop"
        // icon="pi pi-arrow-up"
      />
    </div>
  );
};
