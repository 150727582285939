import React, {useRef, useState, useEffect} from "react";
import {ToastNotification, Spinner, DropdownInput} from "../../../shared";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {ManagerService} from "../../../../api";
import {ActionReturn} from "../../commun/action-return";
import {ActionAdd} from "../../commun/action-add";

export const RegectionForm = (props) => {
    const toastRef = useRef();
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sumbitLoading, setSubmitLoading] = useState(false);
    const [listRejections, setListRejections] = useState([]);
    const [data, setData] = useState(props.data);

    useEffect(() => {
        _loadRegectionsList();
    }, []);

    const _loadRegectionsList = () => {
        setLoading(true);
        ManagerService.getListRejections().then(
            (data) => {
                setListRejections(data.data);
                setLoading(false);
            },
            (error) => {
                toastRef?.current?.showToast({
                    type: "error",
                    title: "Error response",
                    message: error,
                });
                setLoading(false);
            }
        );
    };

    const formik = useFormik({
        initialValues: data,
        validate: (data) => {
            let errors = {};
            if (!data.invoiceId) {
                errors.invoiceId = "invoiceId is required.";
            }
            if (!data.purchaseId) {
                errors.purchaseId = "purchaseId is required.";
            }
            if (!data.supplierId) {
                errors.supplierId = "supplierId is required.";
            }
            if (!data.rejectionId) {
                errors.rejectionId = "rejection is required.";
            }
            return errors;
        },
        onSubmit: (data) => {
            _handleSubmit(data);
            setShowMessage(true);
        },
    });
    const _handleSubmit = (data) => {
        setSubmitLoading(true);
        ManagerService.postOaRejection(data)
            .then(
                (datac) => {
                    toastRef?.current?.showToast({
                        type: "success",
                        title: "Success response",
                        message: datac.message,
                        durable: false,
                    });
                    setSubmitLoading(false);
                    props.refreshData();
                },
                (error) => {
                    toastRef?.current?.showToast({
                        type: "error",
                        title: "Error response",
                        message: error,
                    });
                    setSubmitLoading(false);
                }
            )
            .finally(() => {
            });
    };


    const isFormFieldValid = (name) => {
        return !!(formik.touched[name] && formik.errors[name]);
    };

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <>
            {loading && (
                <div className="my-5">
                    <Spinner label="Processing..."/>
                </div>
            )}
            {!loading && (
                <>

                    <form onSubmit={formik.handleSubmit}>
                        <div className=" container-frm-sb justify-content-center ">
                            <DropdownInput
                                id="rejectionId"
                                name="rejectionId"
                                label="OA status"
                                value={formik?.values?.rejectionId}
                                onChange={formik.handleChange}
                                iconRight="pi pi-directions-alt"
                                options={listRejections}
                                optionLabel="rejectionName"
                                optionValue="id"
                                required
                                getFormErrorMessage={getFormErrorMessage}
                                isFormFieldValid={isFormFieldValid}
                            />
                        </div>
                        <div className="row mt-3">
                            <ActionReturn onClick={props.return}/>
                            <Button
                                type="submit"
                                label="Submit"
                                style={{width: "90%",marginLeft:"1rem"}}
                                loading={sumbitLoading}
                            />
                        </div>

                    </form>

                </>
            )}
            <ToastNotification ref={toastRef}/>
        </>
    );
};
