import React from "react";
import { ActionList } from "../commun/action-list";
import { ActionInfo } from "../commun/action-info";

class Outils {
  supplierTypeBodyTemplate(rowData) {
    return (
      <span
        className={`authorization-badge status-${
          rowData.supplierType === "FOURNISSEURS NATIONAL"
            ? "NATIONAL_SUPPLIER"
            : "INTERNATIONAL_SUPPLIER"
        }`}
      >
        {rowData.supplierType}
      </span>
    );
  }

  //Part manager
  _getColumns = () => {
    return [
      {
        field: "id",
        header: "Id",
        filter: true,
        sortable: true,
        className: "p-columns-large",
      },
      {
        field: "supplierName",
        header: "Name",
        filter: true,
        sortable: true,
        className: "p-columns-x-large",
      },
      {
        field: "supplierType",
        header: "Type",
        filter: true,
        sortable: true,
        className: "p-columns-large",
        body: this.supplierTypeBodyTemplate,
      },

      { field: "action", header: "Action", className: "p-columns-x-small" },
    ];
  };

  _map(data, history, showELement) {
    var list = [];
    data?.map((element, index) => {
      list.push(this._mapElements(element, index, history, showELement));
    });
    return list;
  }

  _mapElements = (data, index, history, showELement) => {
    if (data) {
      return {
        id: data.id,
        supplierName: data.supplierName,
        supplierNumber: data.supplierNumber,
        supplierType: data.supplierType,
        firstContactName: data.firstContactName,
        secondContactName: data.secondContactName,
        firstPhoneNumber: data.firstPhoneNumber,
        secondPhoneNumber: data.secondPhoneNumber,
        firstMailAddress: data.firstMailAddress,
        secondMailAddress: data.secondMailAddress,
        action: this._getAction(data, history, showELement),
      };
    }
  };

  _getAction = (data, history, showELement) => {
    return (
      <div className="form-inline" style={{ display: "block" }}>
        <ActionInfo
          onClick={() => {
            showELement(data);
          }}
        />
        <ActionList
          title="Purchases orders"
          onClick={() => {
            history.push({
              isLocal:data.supplierType=== "FOURNISSEURS NATIONAL",
              pathname: "/suppliersPurchaseOrders",
              customNameData: data.id,
            });
          }}
        />
      </div>
    );
  };

  _getLazyParams = () => {
    return {
      first: 0,
      rows: 10,
      page: 0,
    };
  };
  _getParamFilterlistSuppliers = () => {
    return {
      supplierNumber: "",
      supplierName: "",
      supplierType: "",
    };
  };
}

export default new Outils();
