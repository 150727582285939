import React from 'react';
import { Dialog } from 'primereact/dialog';
import "./popup.css";

export function PopupCos(props) {
    return (
        <Dialog
            position={props.position}
            maximizable={props.maximizable}
            header={props.header}
            visible={props.visible}
            footer={props.footer}
            dismissableMask={props.dismissableMask}
            resizable={false}
            style={props.style}
            contentStyle={props.sticked && { padding: 0 }}
            className={props.className}
            modal
            onHide={props.onHide}
        >
            <div className={props.sticked ? `sticked-popup` : `container-popup`}>
                {props.children}
            </div>
        </Dialog>
    );
}