import React, { useState } from "react";
import utils from "../../utils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Badge } from "./badge";
import { ActionReturn } from "../custom/commun/action-return";
import { ActionFilter } from "../custom/commun/action-filter";
import { ActionAdd } from "../custom/commun/action-add";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { Popup } from "../shared";
import { ImportData } from "../custom/commun/import-data";

export const CustomDataTable = (props) => {
  const [showFilter, setShowFilter] = useState(true);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const columns = props.columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body && col.body}
        headerStyle={{
          overflowWrap: "break-word",
          padding: "5px",
          textAlign: "left",
          fontSize: "11px",
          fontFamily: "ooredoobold",
        }}
        bodyStyle={{ fontSize: "12px", padding: "5px", textAlign: "left" }}
        className={col.className}
        filterMatchMode="contains"
      />
    );
  });
  const _onGlobalFilter = (e) => {
    setGlobalFilter(e.target.value);
  };

  const header = (
    <>
      <div className="header-table">
        <div className="d-flex align-items-center header-title-table my-1 w-100">
          {props.return && (
            <ActionReturn
              onClick={() => {
                props.return();
              }}
            />
          )}
          {props.loading ? (
            <Skeleton width="100%" height="2rem" />
          ) : (
            <>
              <strong className="Grttitre">
                {"  " + utils.subStringGrant(props.title) + ""}
              </strong>
              <strong> {"  " + utils.subStringSmall(props.title) + ""}</strong>
              <Badge
                size="small"
                bgColor="#9e9e9e3d"
                severity="info"
                data={"Total: " + props.totalRecords}
              />
            </>
          )}
        </div>
        {props.new && (
          <div className="p-justify-end  my-1">
            <ActionAdd
              onClick={() => {
                props.new();
              }}
            />
          </div>
        )}
        <div className="p-justify-end  my-1">
          <Button
            className="p-button-icon-csc cs-bag"
            type="button"
            title="Download data"
            onClick={() => setShowReport(true)}
          >
            <i className="pi pi-cloud-download"></i>
          </Button>
        </div>

        {props.filterContainer && (
          <div className="p-justify-end  my-1">
            <ActionFilter
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            />
          </div>
        )}
      </div>
      {showFilter && <div className="filter-cs">{props.filterContainer}</div>}
    </>
  );

  return (
    <>
      <DataTable
        stateKey={props.stateKey}
        value={props.data}
        dataKey="id"
        paginator
        header={header}
        lazy={props.lazy}
        totalRecords={props.totalRecords}
        first={props.first}
        rows={props.rows}
        rowsPerPageOptions={[10, 20, 50]}
        onPage={props.onPage}
        onFilter={props.onFilter}
        filters={props.filters}
        emptyMessage="No records found"
        rowHover
        loading={props.loading}
        globalFilter={globalFilter}
        className="p-datatable-striped "
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      >
        {columns}
      </DataTable>
      <Popup
        title={"Import Data"}
        visible={showReport}
        maximizable
        style={{ width: "300rem" }}
        className="custom-dialog-small"
        content={<ImportData />}
        onClose={() => {
          setShowReport(false);
        }}
      ></Popup>
    </>
  );
};
