import React, { useRef, useState, useEffect } from "react";
import { CustomDataTable, Popup, ToastNotification } from "../../shared";
import { ManagerService } from "../../../api";
import Outils from "./outils";
import { useHistory } from "react-router";
import { InputText } from "primereact/inputtext";
import { SupplierDetails } from "./suppliers-details";
import { ActionClear } from "../commun/action-clear";

export const SuppliersList = () => {
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState();
  const [lazyParams, setLazyParams] = useState(Outils._getLazyParams());
  const [filterParams, setFilterParams] = useState(
    JSON.parse(sessionStorage.getItem("filters")) != undefined
      ? JSON.parse(sessionStorage.getItem("filters"))
      : Outils._getParamFilterlistSuppliers()
  );
  const [clear, setClear] = useState(true);
  const toastRef = useRef();
  const [element, setElement] = useState();
  const dt = useRef(null);
  const history = useHistory();

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, filterParams]);

  const loadLazyData = () => {
    setLoading(true);
    ManagerService.getListSupplier(
      lazyParams.page,
      lazyParams.rows,
      filterParams
    ).then(
      (data) => {
        setData(Outils._map(data.data.elements, history, showELement));
        setTotalRecords(data.data.totalElement);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
      }
    );
  };
  const showELement = (data) => {
    setElement(data);
  };
  const onPage = (event) => {
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  function _handleChange(e) {
    setFilterParams((prevState) => {
      sessionStorage.setItem(
        "filters",
        JSON.stringify({ ...prevState, [e.target.name]: e.target.value })
      );
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  const _clearFilter = () => {
    setClear(true);
    setFilterParams(Outils._getParamFilterlistSuppliers());
    sessionStorage.clear();
  };

  const onFilter = (event) => {
    event["first"] = 0;
    event["rows"] = 10;
    event["page"] = 0;
    setLazyParams(event);
  };

  const _getfilterContainer = () => {
    return (
      <div className=" justify-content-center">
        <div className="row">
          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                type="number"
                id="supplierNumber"
                name="supplierNumber"
                className="p-filter-tab-cs"
                value={filterParams.supplierNumber}
                onChange={_handleChange}
              />
              <label htmlFor="supplierNumber">Id</label>
            </span>
          </div>
          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                id="supplierName"
                name="supplierName"
                className="p-filter-tab-cs"
                value={filterParams.supplierName}
                onChange={_handleChange}
              />
              <label htmlFor="supplierName">Name</label>
            </span>
          </div>
          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                id="supplierType"
                className="p-filter-tab-cs"
                name="supplierType"
                value={filterParams.supplierType}
                onChange={_handleChange}
              />
              <label htmlFor="supplierType">Type</label>
            </span>
          </div>
          <div className="filter-element-btn mt-1">
            <ActionClear onClick={_clearFilter} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomDataTable
        stateKey="suppliers-state-key"
        title="Suppliers"
        icon="pi bi bi-person-lines-fill"
        data={data}
        lazy={true}
        totalRecords={totalRecords}
        loading={loading}
        ref={dt}
        onPage={onPage}
        onSort={onSort}
        onFilter={onFilter}
        filterContainer={_getfilterContainer()}
        clear={clear}
        first={lazyParams.first}
        page={lazyParams.page}
        rows={lazyParams.rows}
        columns={Outils._getColumns()}
        className="table-large"
      />
      <Popup
        title={"Details "}
        visible={element}
        maximizable
        style={{ width: "300rem" }}
        className="custom-dialog-small"
        content={<SupplierDetails element={element} />}
        onClose={() => {
          setElement(null);
        }}
      ></Popup>
      {!loading && <ToastNotification ref={toastRef} />}
    </>
  );
};
