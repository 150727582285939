import utils from "../../../utils";
import React from "react";
import { ActionEdit } from "../commun/action-edit";
import flag_placeholder from "../../../assets/image-recents/flag_placeholder.png";
import { ActionDetails } from "../commun/action-details";

class OutilsPurchageOrders {
  formatCurrency = (amount, currency) => {
    return Number(amount).toLocaleString("us-US", {
      style: "currency",
      currency: currency,
    });
  };

  priceBodyTemplate = (rowData) => {
    return this.formatCurrency(rowData.poAmount, rowData.poCurrencyCode);
  };

  invoicePriceBodyTemplate = (rowData) => {
    return this.formatCurrency(
      rowData.invoiceAmount,
      rowData.invoiceCurrencyCode
    );
  };

  amountPaidPriceBodyTemplate = (rowData) => {
    return this.formatCurrency(rowData.amountPaid, rowData.invoiceCurrencyCode);
  };

  currencyBodyTemplate(rowData) {
    return (
      <React.Fragment>
        <img
          alt="flag"
          src={flag_placeholder}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${rowData.poCurrencyCode}`}
          width={30}
        />
        <span className="image-text" style={{ marginLeft: "0.4rem" }}>
          {rowData.poCurrencyCode}
        </span>
      </React.Fragment>
    );
  }

  invoiceCurrencyBodyTemplate(rowData) {
    return (
      <React.Fragment>
        <img
          alt="flag"
          src={flag_placeholder}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${rowData.poCurrencyCode}`}
          width={30}
        />
        <span className="image-text" style={{ marginLeft: "0.4rem" }}>
          {rowData.poCurrencyCode}
        </span>
      </React.Fragment>
    );
  }

  invoiceCurrencyBodyTemplate(rowData) {
    return (
      <React.Fragment>
        <img
          alt="flag"
          src={flag_placeholder}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${rowData.invoiceCurrencyCode}`}
          width={30}
        />
        <span className="image-text" style={{ marginLeft: "0.4rem" }}>
          {rowData.invoiceCurrencyCode}
        </span>
      </React.Fragment>
    );
  }

  statusBodyTemplate(rowData) {
    return (
      <span
        className={`authorization-badge status-${
          rowData.authorization === "IN PROCESS"
            ? "IN_PROCESS"
            : rowData.authorization === "REQUIRESREAPPROVAL"
            ? "REQUIRES_REAPPROVAL"
            : rowData.authorization
        }`}
      >
        {rowData.authorization}
      </span>
    );
  }

  IvoiuceStatusBodyTemplate(rowData) {
    return (
      <span className={`authorization-badge status-${rowData.invoiceStatus}`}>
        {rowData.invoiceStatus}
      </span>
    );
  }

  //Part orders
  _getColumns = (authorizationBodyTemplate) => {
    return [
      {
        field: "poNumber",
        header: "PO_Nbr",
        filter: true,
        sortable: true,
        className: "p-columns-x-small",
      },
      {
        field: "poDescription",
        header: "Description",
        filter: true,
        sortable: true,
        className: "p-columns-x-large",
      },
      {
        field: "poDate",
        header: "Date",
        filter: true,
        sortable: true,
        className: "p-columns-x-small",
      },
      {
        field: "poApprovedDate",
        header: "Approved Date",
        filter: true,
        sortable: true,
        className: "p-columns-x-small",
      },
      {
        field: "authorization",
        header: "Authorization",
        filter: true,
        //body:authorizationBodyTemplate,
        sortable: true,
        className: "p-columns-small",
        body: this.statusBodyTemplate,
      },
      {
        field: "poCurrencyCode",
        header: "Currency",
        filter: true,
        className: "p-columns-x-small",
        body: this.currencyBodyTemplate,
      },
      {
        field: "poAmount",
        header: "Amount",
        filter: true,
        sortable: true,
        className: "p-columns-x-small",
        body: this.priceBodyTemplate,
      },
      { field: "action", header: "", className: "p-columns-x-small" },
    ];
  };

  _map(data, history, param, isLocal) {
    var list = [];
    data?.map((element, index) => {
      list.push(this._mapElements(element, index, history, param, isLocal));
    });
    return list;
  }

  _mapElements = (data, index, history, param, isLocal) => {
    if (data) {
      return {
        id: index,
        supplierName: data.supplierName,
        supplierNumber: data.supplierNumber,
        poDate: utils.parseToLocaleDateFormat(data.poDate),
        poApprovedDate: utils.parseToLocaleDateFormat(data.poApprovedDate),
        poNumber: data.poNumber,
        poDescription: data.poDescription,
        authorization: data.authorization,
        poCurrencyCode: data.poCurrencyCode,
        poAmount: data.poAmount,
        action: this._getAction(data, history, param, isLocal),
      };
    }
  };

  _getAction = (data, history, param, isLocal) => {
    return (
      <div className="form-inline" style={{ display: "block" }}>
        <ActionDetails
          title="Invoices"
          onClick={() => {
            history.push({
              isLocal: isLocal,
              pathname: "/supplierPurchaseInvoices",
              customNameData: data.poNumber,
              customNameDataSave: param,
            });
          }}
        />
      </div>
    );
  };

  _getLazyParams = () => {
    return {
      first: 0,
      rows: 10,
      page: 0,
    };
  };
  _getParamFilter = () => {
    return {
      poNumber: "",
      poCurrencyCode: "",
      authorization: "",
      poDescription: "",
      poDate: "",
      poApprovedDate: "",
      poAmount: "",
    };
  };
  //Part Invoices
  _getColumnsInvoices = () => {
    return [
      {
        field: "invoiceNum",
        header: "Invoice number",
        filter: true,
        className: "p-columns-midle",
      },
      {
        field: "invoiceStatus",
        header: "Status",
        filter: true,
        className: "p-columns-small",
        body: this.IvoiuceStatusBodyTemplate,
      },
      {
        field: "description",
        header: "Description",
        filter: true,
        className: "p-columns-midle",
      },

      {
        field: "depotDate",
        header: "Depot Date",
        filter: true,
        className: "p-columns-small",
      },
      {
        field: "invoiceAmount",
        header: "Invoice Amount",
        filter: true,
        className: "p-columns-small",
        body: this.invoicePriceBodyTemplate,
      },
      {
        field: "amountPaid",
        header: "Amount paid",
        filter: true,
        className: "p-columns-small",
        body: this.amountPaidPriceBodyTemplate,
      },
      {
        field: "invoiceCurrencyCode",
        header: "Currency",
        filter: true,
        className: "p-columns-small",
        body: this.invoiceCurrencyBodyTemplate,
      },
      {
        field: "invoiceDate",
        header: "Invoice Date",
        filter: true,
        className: "p-columns-small",
      },

      {
        field: "paymentDate",
        header: "Payment Date",
        filter: true,
        className: "p-columns-x-small",
      },
      {
        field: "paymentNumber",
        header: "Payment Nbr",
        filter: true,
        className: "p-columns-x-small",
      },
      { field: "action", header: "", className: "p-columns-x-small" },
    ];
  };

  _mapInvoices(data, _getActionInvoicesDetail, _getActionInvoicesUpdate) {
    var list = [];
    data?.map((element, index) => {
      list.push(
        this._mapElementsInvoices(
          element,
          _getActionInvoicesDetail,
          _getActionInvoicesUpdate
        )
      );
    });
    return list;
  }

  _mapElementsInvoices = (
    data,
    _getActionInvoicesDetail,
    _getActionInvoicesUpdate
  ) => {
    if (data) {
      return {
        supplierNumber: data.supplierNumber,
        supplierName: data.supplierName,
        amountPaid: data.amountPaid,
        depotBankDate: utils.parseToLocaleDateFormat(data.depotBankDate),
        depotDate: utils.parseToLocaleDateFormat(data.depotDate),
        description: data.description,
        feedBackId: data.feedBackId,
        invoiceAmount: data.invoiceAmount,
        invoiceCurrencyCode: data.invoiceCurrencyCode,
        invoiceDate: utils.parseToLocaleDateFormat(data.invoiceDate),
        invoiceNum: data.invoiceNum,
        invoiceStatus: data.invoiceStatus,
        paymentDate: utils.parseToLocaleDateFormat(data.paymentDate),
        paymentNumber: data.paymentNumber,
        recuperationAtfDate: utils.parseToLocaleDateFormat(
          data.recuperationAtfDate
        ),
        rejection: data?.rejection?.rejectionName,
        action: (
          <div className="form-inline" style={{ display: "block" }}>
            <ActionEdit
              title="Update Invoice"
              onClick={() => {
                _getActionInvoicesUpdate(data);
              }}
            />
          </div>
        ),
      };
    }
  };

  _getParamFilterInvoices = () => {
    return {
      invoiceNum: "",
      supplierNumber: "",
      supplierName: "",
      invoiceDate: "",
      description: "",
      invoiceCurrencyCode: "",
      invoiceStatus: "",
    };
  };
}

export default new OutilsPurchageOrders();
