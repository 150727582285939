import Utils from "../utils";
import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_API_URL;
    config.headers.authorization = Utils.getTokenFromStorage();
    config.responseType = "blob";
    return config;
  },
  (error) => Promise.reject(error)
);

const API_REPORTING = process.env.REACT_APP_API_URL_REPORTING;
class ReportingService {
  getPurchaseOrdersReport = (startDate, endDate, supplier) => {
    return axiosInstance.get(
      API_REPORTING +
        `/api/v1/purchase-orders?startDate=${startDate}&endDate=${endDate}&supplierNumber=${supplier}&fileExtension=xlsx`
    );
  };
  getInvoicesReport = (startDate, endDate, supplier) => {
    return axiosInstance.get(
      API_REPORTING +
        `/api/v1/purchase-invoices?startDate=${startDate}&endDate=${endDate}&supplierNumber=${supplier}&fileExtension=xlsx`
    );
  };
}
export default new ReportingService();
