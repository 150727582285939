import axios from "axios";
import Utils from "../utils";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_API_URL;
    config.headers.Authorization = Utils.getTokenFromStorage();
    config.timeout = 1000 * 60 * 10;
    return config;
  },
  (error) => Promise.reject(error)
);

class HttpService {
  get = (suffix) =>
    new Promise((resolve, reject) => {
      axiosInstance.get(suffix).then(
        (response) => {
          response.data.code === 0
            ? resolve(response.data)
            : reject(response.data.message);
        },
        (error) => {
          reject(this.handleErrorResponse(error));
          this.dealExipratedToken(suffix, error.response?.data.code);
        }
      );
    });

  reportGet = (suffix) =>
    new Promise((resolve, reject) => {
      axiosInstance.get(suffix).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(this.handleErrorResponse(error));
          this.dealExipratedToken(suffix, error.response?.data.code);
        }
      );
    });

  post = (suffix, payload) =>
    new Promise((resolve, reject) => {
      axiosInstance.post(suffix, payload).then(
        (response) => {
          response.data.code === 0
            ? resolve(response.data)
            : reject(response.data.message);
        },
        (error) => {
          reject(this.handleErrorResponse(error));
          this.dealExipratedToken(suffix, error.response?.data.code);
        }
      );
    });

  patch = (suffix, payload) =>
    new Promise((resolve, reject) => {
      axiosInstance.patch(suffix, payload).then(
        (response) => {
          response.data.code === 0
            ? resolve(response.data)
            : reject(response.data.message);
        },
        (error) => {
          reject(this.handleErrorResponse(error));
          this.dealExipratedToken(suffix, error.response?.data.code);
        }
      );
    });
  patchSec = (suffix) =>
    new Promise((resolve, reject) => {
      axiosInstance.patch(suffix).then(
        (response) => {
          response.data.code === 0
            ? resolve(response.data)
            : reject(response.data.message);
        },
        (error) => {
          reject(this.handleErrorResponse(error));
          this.dealExipratedToken(suffix, error.response?.data.code);
        }
      );
    });

  delete = (suffix) =>
    new Promise((resolve, reject) => {
      axiosInstance.delete(suffix).then(
        (response) => {
          response.data.code === 0
            ? resolve(response.data)
            : reject(response.data.message);
        },
        (error) => {
          reject(this.handleErrorResponse(error));
          this.dealExipratedToken(suffix, error.response?.data.code);
        }
      );
    });

  handleErrorResponse = (error) => {
    const errorResponse = error.response;
    return errorResponse
      ? errorResponse.data.message
        ? errorResponse.data.message
        : errorResponse.data.statuts
        ? `${errorResponse.data.status} ${errorResponse.data.error}`
        : error.message
      : error.message;
  };

  dealExipratedToken = (suffix, code) => {
    if (suffix.includes("api") && (code === 401 || code === undefined)) {
      console.log("from deal");
      Promise.resolve(Utils.deleteItemFromStorage(Utils.TOKEN_META_KEY)).then(
        () => {
          window.location.reload(false);
        }
      );
    }
  };
}
export default new HttpService();
