import React, { useState } from "react";
import previewImage from "../../../assets/image-recents/login_ico.svg";
import { Input, Checkbox, Alert } from "../../shared";
import logo from "../../../assets/image-recents/logo.png";
import { UserService } from "../../../api";
import { useHistory } from "react-router";
import Utils from "../../../utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState();
  const [rememberMe, setRememberMe] = useState(
    Utils.getItemFromLocalStorage(Utils.REMEMBER_USER_META_KEY)
  );
  const history = useHistory();

  //LOGIN FORMIK
  const formik = useFormik({
    initialValues: {
      username: Utils.getUsernameCredential(),
      password: Utils.getPasswordCredential(),
    },
    validationSchema: Yup.object({
      username: Yup.mixed().required("Username field should not be empty!"),
      password: Yup.mixed().required("Password field should not be empty!"),
    }),
    onSubmit: (values) => {
      submitLoginForm(values);
    },
  });

  //LOGIN FORM SUBMISSION
  const submitLoginForm = (payload) => {
    setLoading(true);
    Utils.manageCacheUserCredentials(payload);
    UserService.login(payload).then(
      (response) => {
        onLoginSuccess(response.Authorization);
      },
      (error) => {
        onLoginFailure(error);
      }
    );
  };

  //ON SUCCESS AUTHENTIFICATION
  const onLoginSuccess = (token) => {
    Promise.resolve(Utils.setItemToStorage(Utils.TOKEN_META_KEY, token)).then(
      () => {
        history.push("/supplier");
      }
    );
  };

  //ON LOGIN FAILURE
  const onLoginFailure = (error) => {
    setLoading(false);
    setErrorForm(error);
  };

  //ON REMEMBER CHECKBOX ACTION
  const onCheckRemember = (checked) => {
    setRememberMe(checked);
    Utils.setItemToStorage(Utils.REMEMBER_USER_META_KEY, checked);
  };

  return (
    <div className="container login-page">
      <div className="row py-5 mt-4">
        <div className="col-md-5 pr-lg- mb-md-0 mx-5">
          <img
            src={previewImage}
            alt=""
            className="img-fluid mb-3 d-none d-md-block w-100"
          />
          <h1>Sign in</h1>
          <p className=" text-muted mb-0 sign-label">
            Welcome to the login page, please sign in to continue.
          </p>
          <p className="text-muted">
            Made By
            <a
              href="mailto:DigitalTechnologyGroup@ooredoo.dz"
              className="text-muted ml-1"
            >
              <u>Ooredoo Digital Team</u>
            </a>
          </p>
        </div>

        {/* <!-- Login Form --> */}

        <section className="h-100 col-md-5">
          <div className="container h-100">
            <div className="card-wrapper w-100">
              <div className="brand text-center mb-4">
                <img src={logo} alt="logo" />
              </div>

              <div className="card">
                <div className="card-body">
                  <h4 className="card-title text-center">Login</h4>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="my-login-validation"
                  >
                    <Input
                      autoComplete="username"
                      id="username"
                      type="text"
                      leftIcon="fa fa-user"
                      placeholder="Username"
                      value={formik.values.username}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setErrorForm(false);
                      }}
                      msgValidation={
                        formik.touched.username && formik.errors.username
                      }
                    />
                    <Input
                      autoComplete="current-password"
                      id="password"
                      type="password"
                      leftIcon="fa fa-lock"
                      placeholder="Password"
                      value={formik.values.password}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setErrorForm(false);
                      }}
                      msgValidation={
                        formik.touched.password && formik.errors.password
                      }
                    />
                    <Checkbox
                      label="Remember me"
                      id="remember"
                      checked={rememberMe}
                      onChange={(e) => {
                        onCheckRemember(e.currentTarget.checked);
                      }}
                    />
                    <Button
                      type="submit"
                      loading={loading}
                      label="Login"
                      className="btn btn-primary btn-block custom-btn p-button-rounded"
                    />
                  </form>
                </div>
              </div>
              <div className="footer text-center copyright">
                Copyright &copy; {new Date().getFullYear()} &mdash; Ooredoo
              </div>
            </div>
            {errorForm && (
              <Alert
                type="danger"
                icon="pi pi-times-circle"
                content={errorForm}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
