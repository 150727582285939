import React, { useRef, useState } from "react";
import { ToastNotification, InpuText } from "../../shared";
import { Button } from "primereact/button";
import utils from "../../../utils";
import { useFormik } from "formik";
import { SuppliersService } from "../../../api";
import { Skeleton } from "primereact/skeleton";

export const SupplierDetails = ({ supplierInfos, refreshData }) => {
  const toastRef = useRef();
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data] = useState({
    firstContactName: supplierInfos.firstContactName,
    firstMailAddress: supplierInfos.firstMailAddress,
    firstPhoneNumber: supplierInfos.firstPhoneNumber,
    secondContactName: supplierInfos.secondContactName,
    secondMailAddress: supplierInfos.secondMailAddress,
    secondPhoneNumber: supplierInfos.secondPhoneNumber,
  });

  const formik = useFormik({
    initialValues: data,
    validate: (formikData) => {
      console.log(formikData);
      let errors = {};

      if (!formikData.firstContactName) {
        errors.firstContactName = "First contact name is required.";
      }
      if (!formikData.firstMailAddress) {
        errors.firstMailAddress = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          formikData.firstMailAddress
        )
      ) {
        errors.firstMailAddress =
          "Invalid email address. E.g. example@email.com";
      }

      if (!formikData.firstPhoneNumber) {
        errors.firstPhoneNumber = "First phone number is required.";
      }

      // else if (
      //   !/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/i.test(
      //     utils.getphonewidthZero(formikData.firstPhoneNumber)
      //   )
      // ) {
      //   errors.firstPhoneNumber = "Invalid phone number.";
      // }

      // second contact

      // if (formikData.secondPhoneNumber) {
      //   if (
      //     !/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/i.test(
      //       utils.getphonewidthZero(formikData.secondPhoneNumber)
      //     )
      //   ) {
      //     errors.secondPhoneNumber = "Invalid phone number.";
      //   }
      // }

      return errors;
    },
    onSubmit: (formikData) => {
      _submit(formikData);
      refreshData();
      setShowMessage(true);
    },
  });

  const isFormFieldValid = (name) => {
    return !!(formik.touched[name] && formik.errors[name]);
  };

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const _submit = (data) => {
    setSubmitLoading(true);
    SuppliersService.pathSupplier(data).then(
      (datac) => {
        toastRef?.current?.showToast({
          type: "success",
          title: "Success response",
          message: datac.message,
          durable: false,
        });
        setSubmitLoading(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setSubmitLoading(false);
      }
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton width="26.5rem" height="20rem" />
      ) : (
        <>
          <form
            onSubmit={formik.handleSubmit}
            style={{ height: "auto!important" }}
          >
            <div className=" container-frm-sb  justify-content-center mt-2">
              <div className="col-12">
                <InpuText
                  id="firstContactName"
                  name="firstContactName"
                  label="First Name Contact"
                  value={formik.values?.firstContactName}
                  onChange={formik.handleChange}
                  iconRight="pi pi-users"
                  required
                  autoFocus
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>
              <div className="col-12">
                <InpuText
                  type="number"
                  id="firstPhoneNumber"
                  name="firstPhoneNumber"
                  label="First Phone Number"
                  iconRight="pi pi-phone"
                  value={formik.values?.firstPhoneNumber}
                  onChange={formik.handleChange}
                  autoFocus
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>
              <div className="col-12">
                <InpuText
                  id="firstMailAddress"
                  name="firstMailAddress"
                  label="First Mail Address"
                  iconRight="pi pi-envelope"
                  value={formik.values?.firstMailAddress}
                  onChange={formik.handleChange}
                  required
                  autoFocus
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>

              <div className="col-12">
                <InpuText
                  id="secondContactName"
                  name="secondContactName"
                  label="Second Name Contact"
                  iconRight="pi pi-users"
                  value={formik.values?.secondContactName}
                  onChange={formik.handleChange}
                  autoFocus
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>

              <div className="col-12">
                <InpuText
                  type="number"
                  id="secondPhoneNumber"
                  name="secondPhoneNumber"
                  label="Second Phone Number"
                  iconRight="pi pi-phone"
                  value={formik.values?.secondPhoneNumber}
                  onChange={formik.handleChange}
                  autoFocus
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>

              <div className="col-12">
                <InpuText
                  id="secondMailAddress"
                  name="secondMailAddress"
                  label="Second Mail Address"
                  iconRight="pi pi-envelope"
                  value={formik.values?.secondMailAddress}
                  onChange={formik.handleChange}
                  autoFocus
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <Button type="submit" loading={submitLoading} label="Submit" />
              </div>
            </div>
          </form>
        </>
      )}
      <ToastNotification ref={toastRef} />
    </>
  );
};
