import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { PrivateRoute } from "./private-route";
import Utils from "../../utils";
import {
  LoginPage,
  PurchaseOrders,
  PurchaseInvoices,
  SuppliersList,
  SupplierPurchaseOrders,
  SupplierPurchaseInvoices,
  ListUsers,
  SupplierPurchaseInvoicesUpdate,
  RejectionsList,
} from "../custom";

export const Routes = () => {
  return (
    <Router history={useHistory} basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/login" component={LoginPage}>
          {Utils.isUserLogged() && <Redirect to="/purchaseOrders" />}
        </Route>
        <PrivateRoute
          path="/purchaseOrders"
          privilege={Utils.roles.get("ROLE_SUPPLIER")}
          component={PurchaseOrders}
        />
        <PrivateRoute
          path="/purchaseInvoices"
          privilege={Utils.roles.get("ROLE_SUPPLIER")}
          component={PurchaseInvoices}
        />

        <PrivateRoute
          path="/suppliers"
          privilege={Utils.rolesList}
          component={SuppliersList}
        />
        <PrivateRoute
          path="/suppliersPurchaseOrders"
          privilege={Utils.rolesList}
          component={SupplierPurchaseOrders}
        />
        <PrivateRoute
          path="/supplierPurchaseInvoices"
          privilege={Utils.rolesList}
          component={SupplierPurchaseInvoices}
        />
        <PrivateRoute
          path="/users"
          privilege={Utils.rolesList}
          component={ListUsers}
        />
        <PrivateRoute
          path="/rejection"
          privilege={Utils.rolesList}
          component={RejectionsList}
        />
        <PrivateRoute
          path="/updateInvoice"
          privilege={Utils.rolesList}
          component={SupplierPurchaseInvoicesUpdate}
        />

        <Route path="*">
          <Redirect to="/purchaseOrders" />
        </Route>
      </Switch>
    </Router>
  );
};
