import React from "react";

export const Input = (props) => {
  return (
    <div className="form-group">
      <div className="input-group">
        {props.leftIcon && (
          <span className="input-group-prepend">
            <div className="input-group-text bg-transparent border-right-0">
              <i className={props.leftIcon} />
            </div>
          </span>
        )}
        {props.label && <label for={props.name}>{props.label}</label>}

        <input
          autoComplete={props.autoComplete}
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          className={`form-control ${props.className}`}
          name={props.id}
          value={props.value}
          required={props.required}
          onChange={props.onChange}
        />
      </div>
      {props.msgValidation && (
        <small class="msg-field-error">{props.msgValidation}</small>
      )}
    </div>
  );
};
