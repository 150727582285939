import React, { useRef, useState, useEffect } from "react";
import { SuppliersService } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { TabMenu } from "primereact/tabmenu";
import { SupplierDetails } from "../supplier/supplier-details";

export const Profil = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const toastRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [fromEdit, setFromEdit] = useState(false);

  const items = [
    {
      label: "First contact",
      icon: "pi pi-fw pi-user",
    },
    { label: "Second contact", icon: "pi pi-fw pi-users" },
    { label: "", icon: "pi pi-fw pi-cog" },
  ];

  useEffect(() => {
    _loadData();
  }, []);

  const _loadData = () => {
    setLoading(true);
    setFromEdit(false);
    SuppliersService.getSupplier().then(
      (datac) => {
        setData(datac.data);
        setLoading(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };
  return (
    <>
      {loading ? (
        <Skeleton width="28rem" height="34rem" />
      ) : (
        <div className="form-demo">
          <div className="p-d-flex p-jc-center">
            <div className="p-fluid">
              <div
                className="p-field"
                style={{ marginBottom: "1.5em", width: "25rem" }}
              >
                <span className="p-float-label">
                  <InputText
                    style={{ opacity: "1" }}
                    id="supplierName"
                    name="supplierName"
                    disabled
                    value={data?.supplierName}
                  />
                  <label htmlFor="supplierName" style={{ fontWeight: "bold" }}>
                    {" "}
                    Supplier name
                  </label>
                </span>
              </div>
              <div
                className="p-field"
                style={{ marginBottom: "1.5em", width: "25rem" }}
              >
                <span className="p-float-label">
                  <InputText
                    style={{ opacity: "1" }}
                    id="supplierType"
                    disabled
                    name="supplierType"
                    value={data?.supplierType}
                  />
                  <label htmlFor="supplierType" style={{ fontWeight: "bold" }}>
                    Supplier type
                  </label>
                </span>
              </div>
              <TabMenu
                model={items}
                activeIndex={activeIndex}
                onTabChange={(e) => {
                  setActiveIndex(e.index);
                  if ((e.index == 0 || e.index == 1) && fromEdit === true) {
                    _loadData();
                  }
                }}
                style={{ marginBottom: "1rem" }}
              />
              {activeIndex == 0 && (
                <>
                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-user" style={{ color: "#E81D26" }} />
                      <InputText
                        style={{ opacity: "1" }}
                        id="firstName"
                        name="firstName"
                        disabled
                        value={data?.firstContactName}
                      />
                      <label htmlFor="firstName" style={{ fontWeight: "bold" }}>
                        Contact name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-phone" style={{ color: "#E81D26" }} />
                      <InputText
                        style={{ opacity: "1" }}
                        id="firstNumber"
                        disabled
                        name="firstNumber"
                        value={data?.firstPhoneNumber}
                      />
                      <label
                        htmlFor="firstNumber"
                        style={{ fontWeight: "bold" }}
                      >
                        Phone number
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i
                        className="pi pi-envelope"
                        style={{ color: "#E81D26" }}
                      />
                      <InputText
                        style={{ opacity: "1" }}
                        id="firstEmail"
                        name="firstEmail"
                        disabled
                        value={data?.firstMailAddress}
                      />
                      <label
                        htmlFor="firstEmail"
                        style={{ fontWeight: "bold" }}
                      >
                        Email
                      </label>
                    </span>
                  </div>
                </>
              )}
              {activeIndex == 1 && (
                <>
                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-user" style={{ color: "#E81D26" }} />
                      <InputText
                        style={{ opacity: "1" }}
                        id="secondName"
                        name="secondName"
                        disabled
                        value={data?.secondContactName}
                      />
                      <label
                        htmlFor="secondName"
                        style={{ fontWeight: "bold" }}
                      >
                        Contact name
                      </label>
                    </span>
                  </div>

                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-phone" style={{ color: "#E81D26" }} />
                      <InputText
                        style={{ opacity: "1" }}
                        id="secondPhone"
                        name="secondPhone"
                        disabled
                        value={data?.secondPhoneNumber}
                      />
                      <label
                        htmlFor="secondPhone"
                        style={{ fontWeight: "bold" }}
                      >
                        Phone number
                      </label>
                    </span>
                  </div>

                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i
                        className="pi pi-envelope"
                        style={{ color: "#E81D26" }}
                      />
                      <InputText
                        style={{ opacity: "1" }}
                        id="secondEmail"
                        name="secondEmail"
                        disabled
                        value={data?.secondMailAddress}
                      />
                      <label
                        htmlFor="secondEmail"
                        style={{ fontWeight: "bold" }}
                      >
                        Email
                      </label>
                    </span>
                  </div>
                </>
              )}
              {activeIndex === 2 && (
                <>
                  <SupplierDetails
                    supplierInfos={data}
                    refreshData={() => {
                      setFromEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
