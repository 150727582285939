import {Button} from "primereact/button";
import React from "react";

export const ActionAdd = (props) => {

    return (
        <Button className='p-button-icon-csc cs-bag'
                type="button"

                title="Add new Item"
                onClick={props.onClick}
        >
            <i className="fa fa-plus"></i>
        </Button>
    )
}