import React, { useState } from "react";
import utils from "../../../utils";
import { InputText } from "primereact/inputtext";
import { TabMenu } from "primereact/tabmenu";

export const PurchaseInvoicesDetail = (props) => {
  const [activeIndex, setActiveIndex] = useState(props.isDataDetails ? 0 : 1);

  const items = [
    {
      label: "Details",
      icon: "pi pi-fw pi-align-justify",
    },
    {
      label: "Invoices",
      icon: "pi pi-fw pi-file-pdf",
      disabled:
        props.data?.invoiceFiles === null ||
        props.data?.invoiceFiles?.length == 0,
    },
  ];

  return (
    <>
      <div className="form-demo">
        <div className="p-d-flex p-jc-center">
          <form className="p-fluid">
            <TabMenu
              model={items}
              activeIndex={activeIndex}
              onTabChange={(e) => {
                setActiveIndex(e.index);
              }}
              style={{ marginBottom: "1rem" }}
            />
            {activeIndex == 0 && (
              <>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "1.5em",
                    marginTop: "1.5em",
                    width: "25rem",
                  }}
                >
                  <span className="p-float-label">
                    <InputText
                      style={{ opacity: "1" }}
                      value={props.data?.invoiceNum}
                      disabled
                    />
                    <label htmlFor="supplierID" style={{ fontWeight: "bold" }}>
                      Invoice Number
                    </label>
                  </span>
                </div>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "1.5em",
                    marginTop: "1.5em",
                    width: "25rem",
                  }}
                >
                  <span className="p-float-label">
                    <InputText
                      style={{ opacity: "1" }}
                      value={props.data?.rejection?.rejectionName}
                      disabled
                    />
                    <label htmlFor="supplierID" style={{ fontWeight: "bold" }}>
                      AP invoice status
                    </label>
                  </span>
                </div>
                {props.data?.invoiceCurrencyCode !== "DZD" && (
                  <>
                    <div
                      className="p-field"
                      style={{
                        marginBottom: "1.5em",
                        marginTop: "1.5em",
                        width: "25rem",
                      }}
                    >
                      <span className="p-float-label p-input-icon-right">
                        <i
                          className="pi pi-calendar"
                          style={{ color: "#E81D26" }}
                        />
                        <InputText
                          style={{ opacity: "1" }}
                          value={utils.parseToLocaleDateFormat(
                            props.data?.depotDate
                          )}
                          disabled
                        />
                        <label
                          htmlFor="supplierID"
                          style={{ fontWeight: "bold" }}
                        >
                          Depot DGE date
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-field"
                      style={{
                        marginBottom: "1.5em",
                        marginTop: "1.5em",
                        width: "25rem",
                      }}
                    >
                      <span className="p-float-label p-input-icon-right">
                        <i
                          className="pi pi-calendar"
                          style={{ color: "#E81D26" }}
                        />
                        <InputText
                          style={{ opacity: "1" }}
                          value={utils.parseToLocaleDateFormat(
                            props.data?.recuperationAtfDate
                          )}
                          disabled
                        />
                        <label
                          htmlFor="supplierID"
                          style={{ fontWeight: "bold" }}
                        >
                          Recuperation ATF date
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-field"
                      style={{
                        marginBottom: "1.5em",
                        marginTop: "1.5em",
                        width: "25rem",
                      }}
                    >
                      <span className="p-float-label  p-input-icon-right">
                        <i
                          className="pi pi-calendar"
                          style={{ color: "#E81D26" }}
                        />
                        <InputText
                          style={{ opacity: "1" }}
                          value={utils.parseToLocaleDateFormat(
                            props.data?.depotBankDate
                          )}
                          disabled
                        />
                        <label
                          htmlFor="supplierID"
                          style={{ fontWeight: "bold" }}
                        >
                          Depot bank date
                        </label>
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
            {activeIndex == 1 && (
              <div
                className=""
                style={{
                  width: "40rem",
                  height: "14.8rem",
                }}
              >
                <div className="d-flex flex-column">
                  {props.data?.invoiceFiles.map((invoice) => (
                    <div className="p-fileupload-row">
                      <div className="p-d-flex p-ai-center p-flex-wrap">
                        <div
                          className="p-d-flex p-ai-center mb-2"
                          style={{ width: "80%" }}
                        >
                          <i
                            className="pi pi-file-pdf"
                            style={{
                              fontSize: "2rem",
                              color: "rgb(232, 29, 38)",
                            }}
                          />
                          <a href={invoice.invoicePath} target="_blank">
                            <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                              {invoice.fileName}
                              <small>
                                {utils.parseToLocaleDateFormat(
                                  invoice.insertionDate
                                )}
                              </small>
                            </span>
                          </a>
                        </div>
                        {/*<span className="p-tag p-component p-tag-warning px-3 py-2">*/}
                        {/*  <span className="p-tag-value">*/}
                        {/*    {`${invoice.fileSize} KB`}*/}
                        {/*  </span>*/}
                        {/*  <span></span>*/}
                        {/*</span>*/}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              // <>
              //   {props.data?.invoiceFiles === null ||
              //   props.data?.invoiceFiles?.length == 0 ? (
              //     <div
              //       className="d-flex justify-content-center align-items-center"
              //       style={{ height: "19.6rem", width: "25rem" }}
              //     >
              //       <i
              //         className="pi pi-info-circle"
              //         style={{ fontSize: "2rem" }}
              //       />
              //       <h5 className="ml-2 mt-2">No invoices</h5>
              //     </div>
              //   ) : (
              //     <div
              //       className="d-flex justify-content-center align-items-center"
              //       style={{ height: "19.6rem", width: "25rem" }}
              //     >
              //       {props.data?.invoiceFiles?.map((invoice) => (
              //         <>
              //           <i class="pi pi-file-o"></i>
              //           <a
              //             href={invoice.invoicePath}
              //             target="_blank"
              //             style={{
              //               fontFamily: "ooredoo-regular",
              //               color: "#E81D26",
              //             }}
              //           >
              //             {invoice.fileName}
              //           </a>
              //           <br></br>
              //         </>
              //       ))}
              //     </div>
              //   )}
              // </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
