import React, { useEffect, useState } from "react";
import { Routes } from "./components/navigation/";
import utils from "./utils";

export const AppContext = React.createContext({});

function App() {

  const [theme, setTheme] = useState(utils.getItemFromLocalStorage(utils.APPTHEME_KEY));

  useEffect(() => {
    utils.setAttributeToDocElement('data-theme', theme === 'dark' ? 'dark' : 'light');
  }, [theme]);

  return (
    <AppContext.Provider value={{ theme, setTheme }}>
      <div data-theme={theme}>
        <Routes />
      </div>
    </AppContext.Provider>

  );
}

export default App;
