import React, { useEffect, useState } from "react";
import UserService from "../../../api/user-service";
import Utils from "../../../utils";
import { Spinner } from "../../shared";

export const Logout = () => {
  const [loader, setLoader] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    function logout() {
      setLoader(true);
      UserService.logout(Utils.getTokenFromStorage()).then(
        () => {
          Promise.resolve(
            Utils.deleteItemFromStorage(Utils.TOKEN_META_KEY)
          ).then(() => {
            window.location.reload(false);
          });
          setLoader(false);
        },
        (error) => {
          setError(error);
          setLoader(false);
        }
      );
    }

    logout();
  }, []);

  return (
    <>
      {loader && <Spinner color="#ED1C24" label="Logging out..." />}
      {error && <span className="logging-out-loader">{error}</span>}
    </>
  );
};
