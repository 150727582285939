import { Button } from "primereact/button";
import React from "react";

export const ActionInfo = (props) => {
  return (
    <Button
      className="p-button-icon-csc"
      type="button"
      title={props.title}
      onClick={props.onClick}
    >
      <i className="pi pi-info-circle"></i>
    </Button>
  );
};
