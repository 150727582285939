import JWTDecoder from "jwt-decoder-claims";
import csv2jsonParser from "convert-csv2json";
import Messages from "./custom-messages";
import { Spinner } from "../components/shared";
import CryptoJS from "crypto-js";
import { ACTION_INVOICES } from "./constants";

class Utils {
  //BEARER TOKEN META KEY
  TOKEN_META_KEY = "PO_APP_AUTHORIZATION";
  REMEMBER_USER_META_KEY = "PO_APP_REMEMBER_ME";
  USERNAME_META_KEY = "PO_APP_USERNAME";
  PASSWORD_META_KEY = "PO_APP_PW";
  APPTHEME_KEY = "PO_APP_THEME";
  NAME = "PO_APP      ";

  //PERSIST ITEM IN LOCAL STORAGE
  setItemToStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  //DELETE ITEM FROM LOCAL STORAGE
  deleteItemFromStorage = (key) => {
    localStorage.removeItem(key);
  };

  //GET ITEM FROM LOCALSTORAGE
  getItemFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  //GET BEARER TOKEN FROM STORAGE
  getTokenFromStorage = () => {
    return JSON.parse(localStorage.getItem(this.TOKEN_META_KEY));
  };

  //DECODING BEARER TOKEN
  decodeJWT = () => {
    const token = this.getTokenFromStorage()?.replace("Bearer ", "");
    return token ? JWTDecoder.payload(token) : null;
  };

  //RESOLVE USER ROLES FROM TOKEN
  getRolesFromToken = () => {
    return this.decodeJWT()?.authorities;
  };

  //GET USERNMAE FROM TOKEN
  getSubFromToken = () => {
    return this.decodeJWT()?.sub;
  };
  getFirstSubFromToken = () => {
    var ch = this.decodeJWT()?.sub;
    return ch?.substring(0, 1);
  };

  //GET SUPPLIER ID FROM TOKEN
  getSupplierID = () => {
    return this.decodeJWT()?.supplier;
  };

  //CHECK IF USER HAS THE APPROPRIATE PRIVILEGE
  isUserPriviliged = (role) => {
    if (typeof role !== "string") {
      return this.isUserPriviligedList(role);
    } else {
      return this.getRolesFromToken().includes(role);
    }
  };

  isUserPriviligedList = (roleList) => {
    let isUserPriviliged = false;
    roleList.forEach((row) => {
      if (this.getRolesFromToken().includes(row)) isUserPriviliged = true;
      return isUserPriviliged;
    });
    return isUserPriviliged;
  };

  //CHECK IF USER IS LOGGED
  isUserLogged = () => {
    try {
      return this.decodeJWT() !== null;
    } catch (err) {
      return false;
    }
  };

  //supplier roles
  roles = new Map([["ROLE_SUPPLIER", "ROLE_SUPPLIER"]]);

  // app access privileges
  rolesList = [
    "ROLE_NEW BUSINESS TECHNOLOGIE",
    "ROLE_DIGITAL DEVELOPMENT",
    "ROLE_AP GROUP",
    "ROLE_FISCALITE TECHNIQUE GROUPE",
    "ROLE_WTACOMMEX",
    "ROLE_CFO PROCESS & QUALITY GROUP",
    "ROLE_SECURITY ANALYTICS GROUP",
  ];

  // administration access roles like user creation
  rolesListDegital = [
    "ROLE_NEW BUSINESS TECHNOLOGIE",
    "ROLE_DIGITAL DEVELOPMENT",
    "ROLE_AP GROUP",
    "ROLE_CFO PROCESS & QUALITY GROUP",
    "ROLE_SECURITY ANALYTICS GROUP",
  ];

  //CONVERT STRING TO NUMBER
  stringToFloat = (strNumber) => {
    return parseFloat(strNumber.replace(",", "."));
  };

  //CONVERT DATE & TIME INTO MILLISECONDS
  convertDatetimeToDouble = (date, time) => {
    return new Date(`${date}T${time}`).getTime();
  };
  //index titre table

  subStringGrant = (str) => {
    if (str.indexOf(",") === -1) return str;
    else return str.substring(0, str.indexOf(","));
  };
  subStringSmall = (str) => {
    if (str.indexOf(",") === -1) return "";
    else return str.substring(str.indexOf(","), str.length);
  };

  //CONVERT MINUTES TOM MILLISECONDS
  convertMinutesToMilliseconds = (minutes) => {
    return minutes * 60000;
  };

  //CONVERT Timestamp
  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum;
  }

  //PARSE DATE TO LOCALE FORMAT STRING
  parseToLocaleDateFormat = (date) => {
    if (date) {
      return this.formatDateSu(date);
    } else return null;
  };

  //BODY TEMPLATE FOR CREATION DATE COLUMN
  dateBodyTemplate = (field) => {
    return (rowData) => {
      return new Date(rowData[field]).toLocaleString();
    };
  };

  //format date
  formatDateSu(date) {
    if (!date) return " ";
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  //CHECK THE MSISDN METADATA IN THE SET
  validateColumnsTemplate = (data) => {
    return true ? "msisdn" in data[0] : false;
  };

  //VALIDATE THE MSISDN FORMAT
  validateRowsTemplate = (data) => {
    let isValidRow = true;
    data.map((row) => {
      if (row.msisdn.length !== 9) isValidRow = false;
      return isValidRow;
    });
    return isValidRow;
  };

  //CONVERT CSV FILE INTO JSON ARRAY
  csvFileToJson = (file) =>
    new Promise((resolve, reject) => {
      csv2jsonParser.csvFile2json(file, ";").then(
        (result) => {
          if (this.validateColumnsTemplate(result)) {
            let cleandResult = result.filter((v) => v.msisdn);
            if (this.validateRowsTemplate(cleandResult)) resolve(cleandResult);
            else reject(Messages.ERROR_BATCH_FORMAT_MSISDN);
          } else {
            reject(Messages.ERROR_CSV_COLUMNS);
          }
        },
        (error) => {
          reject(error.message);
        }
      );
    });

  //CONVERT TO MSISDNS ARR TO STRING
  convertMsisdnsArrayToString = (array) => {
    let temp = "";
    array.map((item) => {
      temp = `${temp}213${item.msisdn},`;
      return temp;
    });
    return temp.substr(0, temp.length - 1);
  };

  //CONVERT SET OF MSISDNs INTO FLAT LIST
  convertMsisdnsToFlatArray = (array) => {
    return array.map((item) => {
      return `213${item.msisdn}`;
    });
  };

  labelTemplate = (template, loading, label) => {
    return loading ? <Spinner color="white" sm label={label} /> : template;
  };

  //MANAGE PERSISTANCE OF USER CREDENTIALS
  manageCacheUserCredentials = (credentials) => {
    if (this.getItemFromLocalStorage(this.REMEMBER_USER_META_KEY)) {
      this.setItemToStorage(this.USERNAME_META_KEY, credentials.username);
      this.setItemToStorage(
        this.PASSWORD_META_KEY,
        this.encryptAES(credentials.password)
      );
    } else {
      this.deleteItemFromStorage(this.USERNAME_META_KEY);
      this.deleteItemFromStorage(this.PASSWORD_META_KEY);
    }
  };

  encryptAES = (plain) => {
    return CryptoJS.AES.encrypt(
      plain,
      process.env.REACT_APP_AES_KEY
    ).toString();
  };

  decryptAES = (encryption) => {
    return CryptoJS.AES.decrypt(
      encryption,
      process.env.REACT_APP_AES_KEY
    ).toString(CryptoJS.enc.Utf8);
  };

  getUsernameCredential = () => {
    return this.getItemFromLocalStorage(this.REMEMBER_USER_META_KEY)
      ? this.getItemFromLocalStorage(this.USERNAME_META_KEY)
      : null;
  };

  getPasswordCredential = () => {
    return this.getItemFromLocalStorage(this.REMEMBER_USER_META_KEY) &&
      this.getItemFromLocalStorage(this.PASSWORD_META_KEY)
      ? this.decryptAES(this.getItemFromLocalStorage(this.PASSWORD_META_KEY))
      : null;
  };

  //SETTING ATT TO DOCUMENT ELEMENT
  setAttributeToDocElement = (attribute, value) => {
    document.documentElement.setAttribute(attribute, value);
  };

  //phone width 213
  getphonewidthZero = (phone) => {
    if (phone.substring(0, 3) === "213") {
      phone = phone.slice(3, phone.length);
      phone = "0" + phone;
    }
    return phone;
  };
  //param request
  getRequestParams = (params) => {
    let requestParams = "";
    if (params) {
      var keys = Object.keys(params);
      keys.forEach(function (key) {
        requestParams = params[key]
          ? requestParams + "&" + key + "=" + params[key]
          : requestParams;
      });
    }
    return requestParams;
  };
  //isUserPriviligedAction
  isUserPriviligedAction = (action) => {
    var role;
    switch (action) {
      case ACTION_INVOICES.OA_REGECTION:
        role = [
          "ROLE_AP GROUP",
          "ROLE_WEBWAP IT GROUP",
          "ROLE_CFO PROCESS & QUALITY GROUP",
        ];
        break;
      case ACTION_INVOICES.ATF_DATE:
        role = [
          "ROLE_FISCALITE TECHNIQUE GROUPE",
          "ROLE_WEBWAP IT GROUP",
          "ROLE_CFO PROCESS & QUALITY GROUP",
        ];
        break;
      case ACTION_INVOICES.BANK_DATE:
        role = [
          "ROLE_WTACOMMEX",
          "ROLE_WEBWAP IT GROUP",
          "ROLE_CFO PROCESS & QUALITY GROUP",
        ];
        break;
      case ACTION_INVOICES.DGE_DATE:
        role = [
          "ROLE_FISCALITE TECHNIQUE GROUPE",
          "ROLE_WEBWAP IT GROUP",
          "ROLE_CFO PROCESS & QUALITY GROUP",
        ];
        break;
      case ACTION_INVOICES.UPLOAD:
        role = [
          "ROLE_AP GROUP",
          "ROLE_WEBWAP IT GROUP",
          "ROLE_CFO PROCESS & QUALITY GROUP",
        ];
        break;
      default:
        role = [];
        break;
    }
    return this.isUserPriviligedList(role);
  };
}

export default new Utils();
export * from "./custom-messages";
export * from "./constants";
