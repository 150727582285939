import Utils from "./index";

export const NAME_SLIDE_PATH = {
  SUPPLIER_NAME_TITRE: { label: "supplier", name: "Supplier" },
  OA_NAME_TITRE: { label: "oa", name: "OA" },
  GLOBALE_NAME_TITRE: { label: "go", name: "go" },
};
export const BASE_ROUTES = {
  PURCHARSE_ORDERS: {
    path: "/purchaseOrders",
    template: "supplier",
    label: "Purchase orders",
  },
  PURCHARSE_EDIT: {
    path: "/purchaseEdit",
    template: "supplier",
    label: "Supplier Information",
  },

  SUPPLIERS_LIST: {
    path: "/suppliers",
    template: "oa",
    label: "Suppliers",
  },

  SUPPLIERS_USER: { path: "/users", template: "oa", label: "Users" },

  SUPPLIERS_REGECTION: {
    path: "/rejection",
    template: "oa",
    label: "Rejections",
  },
};
export const ACTION_INVOICES = {
  OA_REGECTION: "Regection",
  DGE_DATE: "DGE date",
  ATF_DATE: "ATF date",
  BANK_DATE: "BANK date",
  UPLOAD: "Upload",
};
export const NAME_APP = "Ooredoo PO";
