import React from "react";
import { ActionDelete } from "../commun/action-delete";
import utils from "../../../utils";

class OutilsUsers {
  _getLazyParams = () => {
    return {
      first: 0,
      rows: 10,
      page: 0,
    };
  };
  _getColumns = (activeBodyTemplate) => {
    return [
      {
        field: "username",
        header: "Username",
        filter: true,
        sortable: true,
        className: "p-columns-large",
      },
      {
        field: "firstContactName",
        header: "Supplier Name",
        filter: true,
        sortable: true,
        className: "p-columns-large",
      },
      {
        field: "supplierNumber",
        header: "Supplier Number",
        filter: true,
        sortable: true,
        className: "p-columns-large",
      },
      {
        field: "creationDate",
        header: "Creation date",
        filter: true,
        sortable: true,
        className: "p-columns-large",
      },
      {
        field: "createdBy",
        header: "Created by",
        filter: true,
        sortable: true,
        className: "p-columns-large",
      },
      {
        field: "active",
        header: "Active",
        filter: false,
        body: activeBodyTemplate,
        sortable: true,
        className: "p-columns-small",
      },
      {
        field: "action",
        header: "Action",
        filter: true,
        sortable: true,
        className: "p-columns-x-small",
      },
    ];
  };

  _map(data, deleteUser) {
    var list = [];
    data?.map((element) => {
      list.push({
        userId: element.userId,
        username: element.username,
        firstContactName: element.supplier.firstContactName,
        supplierNumber: element.supplier.supplierNumber,
        creationDate: utils.parseToLocaleDateFormat(element.creationDate),
        createdBy: element.createdBy,
        active: element.active ? "Enabled" : "Disabled",
        action: (
          <ActionDelete
            itemLabel="Username"
            active={element.active}
            itemValue={element.username}
            onClick={() => {
              deleteUser(element.userId);
            }}
          />
        ),
      });
    });
    return list;
  }
}
export default new OutilsUsers();
