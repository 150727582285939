import React from 'react';
import { Redirect, Route } from "react-router-dom";
import Utils from '../../utils';
import { RootContainer } from '../layout';
import { AccessDenied } from './access-denied';

export const PrivateRoute = ({ component: Component, ...rest }) => {

    const isAuthenticated = () => {
        return Utils.isUserLogged();
    }

    return (
        <Route {...rest}
            render={props => isAuthenticated() ?
                <RootContainer>
                    {
                        rest.privilege ? (
                            Utils.isUserPriviliged(rest.privilege) ?
                                <Component {...props} /> :<AccessDenied />
                        ) : <Component {...props} />
                    }
                </RootContainer>
                : <Redirect to={{ pathname: "/login" }} />
            } />
    );
}