import React, { useState, useRef, useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import "../../../../assets/scss/file-upload.css";
import { ProgressBar } from "primereact/progressbar";

export const UploadForm = (props) => {
  const [totalSize, setTotalSize] = useState(0);
  const [totalSizeSave, setTotalSizeSave] = useState(0);
  const [totalFile, setTotalFile] = useState(0);
  const [loading, setLoading] = useState(false);
  const fileUploadRef = useRef(null);

  useEffect(() => {
    setLoading(false);
    setTotalSize(totalSize - totalSizeSave);
    setTotalFile(totalSizeSave === 0 ? totalFile : totalFile - 1);
    setTotalSizeSave(0);
  }, [totalSizeSave]);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let _totalFile = totalFile;
    for (let file of e.files) {
      _totalFile += 1;
      _totalSize += file.size;
    }
    setTotalSize(_totalSize);
    setTotalFile(_totalFile);
  };

  const remove = (file) => {
    let _totalSize = totalSize;
    let _totalFile = totalFile;
    _totalFile = _totalFile - 1;
    _totalSize -= file.size;
    setTotalFile(_totalFile);
    setTotalSize(_totalSize);
  };

  const onTemplateRemove = (file, callback) => {
    remove(file);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
    setTotalFile(0);
  };
  const onValidationFail = (file) => {
    setLoading(true);
    setTotalSizeSave(file.size);
  };

  const onError = (e) => {
    onTemplateClear();
    props.onError(e);
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 20000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        {!loading && (
          <ProgressBar
            value={value}
            displayValueTemplate={() => `${formatedValue} / 2 MB`}
            style={{ width: "300px", height: "20px", marginLeft: "auto" }}
          ></ProgressBar>
        )}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className="p-d-flex p-ai-center" style={{ width: "40%" }}>
          {file.type === "application/pdf" ? (
            <i
              className="pi pi-file-pdf"
              style={{ fontSize: "2rem", color: "#E81D26" }}
            />
          ) : (
            <img
              alt={file.name}
              role="presentation"
              src={file.objectURL}
              width={40}
            />
          )}
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file-pdf mt-2 p-3"
          style={{
            fontSize: "2em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{
            fontSize: "1em",
            marginLeft: "2em",
            color: "var(--text-color-secondary)",
          }}
          className="my-5"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <FileUpload
      ref={fileUploadRef}
      name={props.name}
      url={props.url}
      disabled={totalFile === 3}
      multiple
      accept=".pdf,image/*"
      maxFileSize={2000000}
      onUpload={props.onUpload}
      onBeforeSend={props.onBeforeSend}
      onError={onError}
      onSelect={onTemplateSelect}
      onClear={onTemplateClear}
      onValidationFail={onValidationFail}
      headerTemplate={headerTemplate}
      itemTemplate={itemTemplate}
      emptyTemplate={emptyTemplate}
      chooseOptions={chooseOptions}
      uploadOptions={uploadOptions}
      cancelOptions={cancelOptions}
    />
  );
};
