import React, { useRef, useState } from "react";
import { ToastNotification, PasswordInputText } from "../../shared";
import { useFormik } from "formik";
import { Button } from "primereact/button";

export const ResetPwdForm = (props) => {
  const toastRef = useRef();
  const [showMessage, setShowMessage] = useState(false);
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const formik = useFormik({
    initialValues: data,
    validate: (data) => {
      let errors = {};
      if (!data.oldPassword) {
        errors.oldPassword = "Old password is required.";
      }
      if (!data.newPassword) {
        errors.newPassword = "New password is required.";
      }
      if (!data.confirmPassword) {
        errors.confirmPassword = "Password confirmation is required.";
      }
      if (data.confirmPassword !== data.newPassword) {
        errors.confirmPassword = "Confirmation password do not match.";
      }
      return errors;
    },
    onSubmit: (data) => {
      props._submit(data);
      props._loadData();
      setShowMessage(true);
    },
  });

  const isFormFieldValid = (name) => {
    return !!(formik.touched[name] && formik.errors[name]);
  };

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-demo">
          <div className="p-d-flex p-jc-center">
            <div className="p-fluid">
              <div>
                <PasswordInputText
                  autoComplete="new-password"
                  id="oldPassword"
                  name="oldPassword"
                  label="Old password"
                  type="Password"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  required
                  autoFocus
                  toggleMask
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>

              <div className=" mb-3">
                <PasswordInputText
                  autoComplete="new-password"
                  id="newPassword"
                  name="newPassword"
                  label="New password"
                  type="Password"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  required
                  autoFocus
                  toggleMask
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>
              <div className=" mb-3">
                <PasswordInputText
                  autoComplete="new-password"
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm password"
                  type="Password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  required
                  autoFocus
                  //  toggleMask
                  getFormErrorMessage={getFormErrorMessage}
                  isFormFieldValid={isFormFieldValid}
                />
              </div>

              <Button
                label="Submit"
                loading={props.progress}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </form>
      <ToastNotification ref={toastRef} />
    </>
  );
};
