import {Button} from "primereact/button";
import React from "react";

export const ActionDetails = (props) => {

    return (
        <Button className='p-button-icon-csc'
                type="button"
                title={props.title}
                onClick={props.onClick}
        >
            <i className="pi pi-file"></i>
        </Button>
    )
}