import React from "react";

export const Checkbox = (props) => {
  return (
    <div className="form-group">
      <div className="custom-checkbox custom-control">
        <input
          type="checkbox"
          name={props.id}
          id={props.id}
          className="custom-control-input"
          onChange={props.onChange}
          checked={props.checked}
        />
        <label
          htmlFor={props.id}
          style={{ cursor: "pointer" }}
          className="custom-control-label"
        >
          {props.label}
        </label>
      </div>
    </div>
  );
};
