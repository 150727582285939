import React, {useRef, useState} from 'react';
import {Spinner, ToastNotification} from "../../shared";
import {ResetPwdForm} from "./reset-pwd-form";
import UserService from "../../../api/user-service";

export const ResetPwd = (props) => {

    const [loading, setLoading] = useState(false);
    const toastRef = useRef();

    const _submit=(data)=>{
        setLoading(true);
        UserService.resetPassword(data).then(datac => {
            setLoading(false)
            props.closePopup();
            props.showSuccessToast("success","Success response",datac.message,false);
        }, error => {
            toastRef?.current?.showToast({type: "error", title: "Error response", message: error});
            setLoading(false);
        })
    }

    return (
        <>
            {/* {
                (loading) &&
                <div className="my-5">
                    <Spinner label="Processing..."/>
                </div>
            } */}
            {/* {
                (!loading) && <> */}
                    <ResetPwdForm username={props.username} _submit={_submit} _loadData={()=>{}} progress ={loading}/>
                {/* </>
            } */}
            <ToastNotification ref={toastRef}/>
        </>
    );
}