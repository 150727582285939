import React from 'react';


export const Spinner = (props) => {

    const size = props.sm && "20px";
    const color = props.color ? props.color : "#ed1c24";

    return (
        <div className="text-center-element">
            <div className="spinner-border" role="status" style={{ height: size, width: size, color: color, animation: 'spinner-border .35s linear infinite' }}>
                <span className="sr-only">Loading...</span>
            </div>
            <span style={{ margin: "0.9rem" }}>{props.label ? props.label : "Loading..."}</span>
        </div>
    );
}
