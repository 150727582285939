import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { PopupCos } from "../../shared";

export const ActionDelete = (props) => {
  const [visible, setVisible] = useState(false);
  const [titre, setTitre] = useState("Delete");

  useEffect(() => {
    if(props.active !== undefined){
      setTitre(props.active === false ? "Enable" : "Disable");
    }
  }, [props.active]);

  function _show(e) {
    setVisible(true);
  }

  function _hide() {
    setVisible(false);
  }

  function _confirmDone() {
    setVisible(false);
    props.onClick();
  }
  return (
    <div className="form-inline" style={{ display: "block" }}>
      <Button
        className="p-button-icon-csc"
        type="button"
        title={titre}
        onClick={_show}
      >
        {props.active === undefined ? <i className="pi pi-trash"></i> : ""}
        {props.active === false ? <i className="pi pi-lock"></i> : ""}
        {props.active === true ? <i className="pi pi-lock-open"></i> : ""}
      </Button>

      <PopupCos header={props.itemValue} visible={visible} onHide={_hide}>
        <div>
          <div className="confirmation-content">
            <i
              className="pi pi-question-circle p-mr-3"
              style={{ fontSize: "2rem", margin: "0.5rem" }}
            />
            <span>Are you sure you want to {titre} this item?</span>
          </div>
          <div className="row">
            <div className="col-6" />
            <div className="col-3">
              <Button
                label="No"
                //icon='pi pi-times'
                type="button"
                id="no-btn"
                onClick={_hide}
                className="p-button-warning btc"
              />
            </div>
            <div className="col-3">
              <Button
                label="Yes"
                //icon='pi pi-check'
                type="button"
                id="yes-btn"
                onClick={_confirmDone}
                className="p-button-text"
                autoFocus
              />
            </div>
          </div>
        </div>
      </PopupCos>
    </div>
  );
};
