import React from "react";
import { InputText } from "primereact/inputtext";

export const InpuText = (props) => {
  return (
    <div className="p-float-inpuText">
      <span
        className={
          props.iconRight ? "p-float-label p-input-icon-right" : "p-float-label"
        }
      >
        <i className={props.iconRight} />
        <InputText
          autoComplete={props.autoComplete}
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          name={props.name}
          format={props.format}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange}
          autoCapitalize={false}
          autoFocus={false}
          toggleMask={props.toggleMask}
          className={
            props.isFormFieldValid(props.name)
              ? "p-error p-inputtext-size "
              : "p-valid p-inputtext-size"
          }
        />
        <label>
          {props.label}
          {props.required ? "*" : ""}
        </label>
      </span>
      <div className="p-float-inpuText-erreur">
        {props.getFormErrorMessage(props.name)}
      </div>
    </div>
  );
};
