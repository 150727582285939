import {Button} from "primereact/button";
import React from "react";

export const ActionFilter = (props) => {

    return (
        <Button className='p-button-icon-csc cs-bag'
                type="button"
                title="Filter"
                onClick={props.onClick}
        >
            <i className="pi pi-sliders-h"></i>
        </Button>
    )
}