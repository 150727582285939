import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/image-recents/logo.png";
import "bootstrap/dist/js/bootstrap.bundle";
import {
  Button,
  ProfileDropdownMenu,
  Popup,
  ToastNotification,
} from "../shared";
import { Logout, Profil, ResetPwd } from "../custom";
import utils, { BASE_ROUTES } from "../../utils";

export const Header = (props) => {
  const toastRef = useRef();
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [confirmlogout, setConfirmlogout] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [action, setAction] = useState();
  const [roles, setRoles] = useState({
    ROLE_SUPPLIER: utils.isUserPriviliged(utils.roles.get("ROLE_SUPPLIER")),
    ROLE_OA: utils.isUserPriviliged(utils.rolesList),
  });

  useEffect(() => {
    if (roles.ROLE_SUPPLIER) {
      setAction(BASE_ROUTES.PURCHARSE_ORDERS.template);
    }
    if (roles.ROLE_OA) {
      setAction(BASE_ROUTES.SUPPLIERS_LIST.template);
    }
  }, []);

  useEffect(() => {
    props.pathSlideContainer(action);
  }, [action]);

  const dropdown = roles.ROLE_SUPPLIER
    ? [
        {
          label: "My Profile",
          icon: "pi pi-user",
          action: () => setShowProfilePopup(true),
        },
        {
          label: "Reset Password",
          icon: "pi pi-lock",
          action: () => setShowResetPopup(true),
        },
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          action: () => setDisplayConfirmation(true),
        },
      ]
    : [
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          action: () => setDisplayConfirmation(true),
        },
      ];

  const getListDropdown = () => {
    return dropdown;
  };

  const lougout = () => {
    setDisplayConfirmation(false);
    setConfirmlogout(true);
  };

  const showMessage = (type, title, message, durable) => {
    toastRef?.current?.showToast({
      type: type,
      title: title,
      message: message,
      durable: durable,
    });
  };
  return (
    <nav className="navbar navbar-light custom-header shadow">
      <div className="left-nav">
        <a className="navbar-brand" href={window.location.href}>
          <img src={logo} style={{ width: "11rem" }} alt="logo" />
        </a>
      </div>
      <div className="bar-nav-menu-cs"></div>
      <div className="right-nav mr-2">
        <ProfileDropdownMenu
          toggleElement={
            <>
              <span className="p-button-icon-csc-header">
                {utils.getFirstSubFromToken()}
              </span>
            </>
          }
          header={{
            label: utils.getSubFromToken(),
            icon: "pi pi-user-circle",
            subLabel: utils.getSubFromToken(),
          }}
          items={getListDropdown()}
          footer={""}
        />
        <Popup
          title="Confirmation"
          visible={displayConfirmation}
          dismissable
          onClose={() => setDisplayConfirmation(false)}
          content={
            <div className="confirmation-content">
              <i
                className="pi pi-question-circle"
                style={{ fontSize: "2rem", marginRight: "0.5rem" }}
              />
              <span>Are you sure you want to logout?</span>
            </div>
          }
          footer={
            confirmlogout ? (
              <Logout />
            ) : (
              <div className="d-flex float-right">
                <Button
                  type="button"
                  label="Cancel"
                  className="btn-secondary"
                  onClick={() => setDisplayConfirmation(false)}
                />
                &nbsp;
                <Button
                  type="button"
                  label="OK"
                  className="btn-danger"
                  onClick={lougout}
                />
              </div>
            )
          }
        />
        <Popup
          title={"My Profile"}
          visible={showProfilePopup}
          maximizable
          className="custom-dialog-small"
          content={<Profil />}
          onClose={() => {
            setShowProfilePopup(false);
          }}
        ></Popup>
        <Popup
          title={`Reset password for user: ${utils.getSubFromToken()}`}
          visible={showResetPopup}
          className="custom-dialog-small"
          content={
            <ResetPwd
              username={utils.getSubFromToken()}
              closePopup={() => {
                setShowResetPopup(false);
              }}
              showSuccessToast={(type, title, message, durable) => {
                showMessage(type, title, message, durable);
              }}
            />
          }
          onClose={() => {
            setShowResetPopup(false);
          }}
        ></Popup>
      </div>
      <ToastNotification ref={toastRef} />
    </nav>
  );
};
