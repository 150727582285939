import React, { useRef, useState, useEffect } from "react";
import { ToastNotification } from "../../shared";
import { SuppliersService } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { TabMenu } from "primereact/tabmenu";

export const SupplierDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const toastRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    {
      label: "First contact",
      icon: "pi pi-fw pi-user",
    },
    { label: "Second contact", icon: "pi pi-fw pi-users" },
  ];

  useEffect(() => {
    setData(props.element);
  }, []);

  const _loadData = () => {
    setLoading(true);
    SuppliersService.getSupplier().then(
      (datac) => {
        setData(datac.data);
        setLoading(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton width="37.5rem" height="34rem" />
      ) : (
        <div className="form-demo">
          <div className="p-d-flex p-jc-center">
            <form className="p-fluid">
              <div
                className="p-field"
                style={{ marginBottom: "1.5em", width: "25rem" }}
              >
                <span className="p-float-label">
                  <InputText
                    style={{ opacity: "1" }}
                    id="supplierName"
                    name="supplierName"
                    disabled
                    value={data?.supplierName}
                  />
                  <label htmlFor="supplierName" style={{ fontWeight: "bold" }}>
                    {" "}
                    Supplier name
                  </label>
                </span>
              </div>
              <div
                className="p-field"
                style={{ marginBottom: "1.5em", width: "25rem" }}
              >
                <span className="p-float-label">
                  <InputText
                    style={{ opacity: "1" }}
                    id="supplierType"
                    disabled
                    name="supplierType"
                    value={data?.supplierType}
                  />
                  <label htmlFor="supplierType" style={{ fontWeight: "bold" }}>
                    Supplier type
                  </label>
                </span>
              </div>
              <TabMenu
                model={items}
                activeIndex={activeIndex}
                onTabChange={(e) => {
                  setActiveIndex(e.index);
                }}
                style={{ marginBottom: "1rem" }}
              />
              {activeIndex == 0 && (
                <>
                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-user" style={{ color: "#E81D26" }} />
                      <InputText
                        style={{ opacity: "1" }}
                        id="firstName"
                        name="firstName"
                        disabled
                        value={data?.firstContactName}
                      />
                      <label htmlFor="firstName" style={{ fontWeight: "bold" }}>
                        First contact name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-phone" style={{ color: "#E81D26" }} />
                      <InputText
                        style={{ opacity: "1" }}
                        id="firstNumber"
                        disabled
                        name="firstNumber"
                        value={data?.firstPhoneNumber}
                      />
                      <label
                        htmlFor="firstNumber"
                        style={{ fontWeight: "bold" }}
                      >
                        First contact number
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i
                        className="pi pi-envelope"
                        style={{ color: "#E81D26" }}
                      />
                      <InputText
                        style={{ opacity: "1" }}
                        id="firstEmail"
                        name="firstEmail"
                        disabled
                        value={data?.firstMailAddress}
                      />
                      <label
                        htmlFor="firstEmail"
                        style={{ fontWeight: "bold" }}
                      >
                        First contact email
                      </label>
                    </span>
                  </div>
                </>
              )}
              {activeIndex == 1 && (
                <>
                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-user" style={{ color: "#E81D26" }} />
                      <InputText
                        style={{ opacity: "1" }}
                        id="secondName"
                        name="secondName"
                        disabled
                        value={data?.secondContactName}
                      />
                      <label
                        htmlFor="secondName"
                        style={{ fontWeight: "bold" }}
                      >
                        Second contact name
                      </label>
                    </span>
                  </div>

                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-phone" style={{ color: "#E81D26" }} />
                      <InputText
                        style={{ opacity: "1" }}
                        id="secondPhone"
                        name="secondPhone"
                        disabled
                        value={data?.secondPhoneNumber}
                      />
                      <label
                        htmlFor="secondPhone"
                        style={{ fontWeight: "bold" }}
                      >
                        Second contact number
                      </label>
                    </span>
                  </div>

                  <div
                    className="p-field"
                    style={{ marginBottom: "1.5em", width: "25rem" }}
                  >
                    <span className="p-float-label p-input-icon-right">
                      <i
                        className="pi pi-envelope"
                        style={{ color: "#E81D26" }}
                      />
                      <InputText
                        style={{ opacity: "1" }}
                        id="secondEmail"
                        name="secondEmail"
                        disabled
                        value={data?.secondMailAddress}
                      />
                      <label
                        htmlFor="secondEmail"
                        style={{ fontWeight: "bold" }}
                      >
                        Second contact email
                      </label>
                    </span>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      )}
      <ToastNotification ref={toastRef} />
    </>
  );
};
