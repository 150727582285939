import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/style.scss";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/theme/theme.scss";
import "./assets/scss/style-overi.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
