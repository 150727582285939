import React, { useRef, useState, useEffect } from "react";
import {
  ToastNotification,
  InpuText,
  PasswordInputText,
  DropdownInput,
} from "../../shared";
import { useFormik } from "formik";
import { Skeleton } from "primereact/skeleton";
import { ManagerService } from "../../../api";
import { Button } from "primereact/button";

export const AddUserForm = (props) => {
  const toastRef = useRef();
  let loadLazyTimeout = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [reset, setReset] = useState(false);
  const [lazyItems, setLazyItems] = useState([]);
  const [index, setIndex] = useState(0);
  const [size, setSize] = useState(30);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    supplierId: null,
    email: "",
  });

  useEffect(() => {
    formik.resetForm(data);
    setReset(false);
    // _loadData(index, size, true);
  }, [reset]);

  const _loadData = (index, size, isFirst) => {
    setLoading(true);
    if (isFirst) setLoadingFirst(true);
    ManagerService.getListSupplier(index, size, "").then(
      (datac) => {
        const _lazyItems = [...lazyItems];
        _lazyItems.push.apply(_lazyItems, datac.data.elements);
        setLazyItems(_lazyItems);
        setLoading(false);
        if (isFirst) setLoadingFirst(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
        if (isFirst) setLoadingFirst(false);
      }
    );
  };

  const _filter = (index, size, filter) => {
    const param = { supplierName: filter };
    setLoading(true);
    ManagerService.getListSupplier(index, size, param).then(
      (datac) => {
        if (datac.data.totalElement != 0) {
          setLazyItems(datac.data.elements);
        }
        setLoading(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };

  const formik = useFormik({
    initialValues: data,
    validate: (data) => {
      let errors = {};
      if (!data.username) {
        errors.username = "Username is required.";
      }
      if (!data.password) {
        errors.password = "Password is required.";
      }
      if (!data.supplierId) {
        errors.supplierId = "SupplierId is required.";
      }

      if (!data.confirmPassword) {
        errors.confirmPassword = "Password confirmation is required.";
      }
      if (data.confirmPassword !== data.password) {
        errors.confirmPassword = "Confirmation password do not match.";
      }
      if (!data.email) {
        errors.email = "Email is required";
      }

      return errors;
    },
    onSubmit: (data) => {
      props._submit(data);
      props._loadData();
      setReset(true);
      setShowMessage(true);
    },
  });

  const _reset = () => {
    setReset(true);
  };

  const onFilter = (event) => {
    setIndex(0);
    if (event.filter !== "") {
      _filter(0, size, event.filter);
    } else {
      setLazyItems([]);
    }
  };

  const onLazyLoad = (event) => {
    const { first, last, filter } = event;
    if (event.filter === "") {
      setLazyLoading(true);
      if (loadLazyTimeout) {
        clearTimeout(loadLazyTimeout);
      }
      if (index == 0 && last == 0) {
        _loadData(index, size, false);
      } else if (last == lazyItems.length) {
        _loadData(index, size, false);
        setIndex(index + 1);
      }
      //imitate delay of a backend call
      loadLazyTimeout = setTimeout(() => {
        setLazyLoading(false);
      }, Math.random() * 1000 + 250);
    }
  };

  const itemOptionTemplate = (option) => {
    return (
      <div className="p-filter-dropd-cs">
        <div>{option.supplierName}</div>
      </div>
    );
  };

  const isFormFieldValid = (name) => {
    return !!(formik.touched[name] && formik.errors[name]);
  };

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      {loadingFirst ? (
        <Skeleton width="25rem" height="15rem"></Skeleton>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-demo">
              <div className="p-d-flex p-jc-center">
                <div className="p-fluid">
                  <InpuText
                    autoComplete="username"
                    id="username"
                    name="username"
                    label="Username"
                    iconRight="pi pi-user"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    required
                    autoFocus
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />
                  <PasswordInputText
                    autoComplete="new-password"
                    id="password"
                    name="password"
                    label="Password"
                    type="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    required
                    toggleMask
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />
                  <PasswordInputText
                    autoComplete="new-password"
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm password"
                    type="Password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    required
                    // toggleMask
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />
                  <DropdownInput
                    id="supplierId"
                    name="supplierId"
                    label="Supplier*"
                    value={formik.values.supplierId}
                    onChange={formik.handleChange}
                    iconRight="pi pi-users"
                    options={lazyItems}
                    optionLabel="supplierName"
                    itemTemplate={itemOptionTemplate}
                    virtualScrollerOptions={{
                      lazy: true,
                      onLazyLoad: onLazyLoad,
                      itemSize: 38,
                      showLoader: true,
                      loading: lazyLoading,
                      delay: 250,
                      loadingTemplate: (options) => {
                        return (
                          <div className="flex align-items-center p-2">
                            <Skeleton
                              width={options.even ? "60%" : "50%"}
                              height="1rem"
                            />
                          </div>
                        );
                      },
                    }}
                    optionValue="id"
                    filter={true}
                    onFilter={onFilter}
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />
                  <InpuText
                    type="email"
                    autoComplete="email"
                    id="email"
                    name="email"
                    label="Email"
                    iconRight="pi pi-envelope"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    required
                    autoFocus
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />
                  <br></br>
                  <Button
                    type="submit"
                    label="Submit"
                    loading={props.loading}
                  />
                </div>
              </div>
            </div>
          </form>
        </>
      )}
      <ToastNotification ref={toastRef} />
    </>
  );
};
