import React from "react";
import { ActionDelete } from "../commun/action-delete";
import utils from "../../../utils";

class Outils {
  _getColumnsRegections = () => {
    return [
      // {
      //   field: "id",
      //   header: "Id",
      //   filter: true,
      //   sortable: true,
      //   className: "p-columns-small",
      // },
      {
        field: "rejectionName",
        header: "Name",
        filter: true,
        sortable: true,
        className: "p-columns-x-large",
      },
      {
        field: "creationDate",
        header: "Creation date",
        filter: true,
        sortable: true,
        className: "p-columns-x-large",
      },
      {
        field: "createdBy",
        header: "Created by",
        filter: true,
        sortable: true,
        className: "p-columns-x-large",
      },
      {
        field: "action",
        header: "Action",
        filter: true,
        sortable: true,
        className: "p-columns-x-small",
      },
    ];
  };

  _mapRegections(data, deleteRejection) {
    var list = [];
    data?.map((element) => {
      list.push({
        id: element.id,
        rejectionName: element.rejectionName,
        creationDate: utils.parseToLocaleDateFormat(element.creationDate),
        createdBy: element.createdBy,
        action: (
          <ActionDelete
            itemLabel="Name"
            itemValue={element.rejectionName}
            onClick={() => {
              deleteRejection(element.id);
            }}
          />
        ),
      });
    });
    return list;
  }

  _getLazyParams = () => {
    return {
      first: 0,
      rows: 10,
      page: 0,
    };
  };
}

export default new Outils();
