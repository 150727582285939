import React, { useRef, useState, useEffect } from "react";
import { Popup, ToastNotification } from "../../shared";
import utils, { ACTION_INVOICES } from "../../../utils";
import { useHistory } from "react-router";
import {
  AtfForm,
  BankForm,
  DgeForm,
  RegectionForm,
  UploadForm,
} from "./action-form";
import { ManagerService } from "../../../api";
import { TabMenu } from "primereact/tabmenu";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";

export const SupplierPurchaseInvoicesUpdate = (props) => {
  const [data, setData] = useState();
  const [poNumber, setPoNumber] = useState(props.data.poNumber);
  const [invoiceNum, setInvoiceNum] = useState(props.data.invoiceNum);
  const [isLocal, setIsLocal] = useState(props.data.isLocal);
  const [supplier, setSupplier] = useState(props.data.supplier);
  const [loading, setLoading] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [regectionData, setRegectionData] = useState();
  const [atfData, setAtfData] = useState();
  const [dgeData, setDgeData] = useState();
  const [bankData, setBankData] = useState();
  const [url, setUrl] = useState();
  const history = useHistory();
  const toastRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    {
      label: "Details",
      icon: "pi pi-fw pi-align-justify",
    },
    {
      label: "Invoices",
      icon: "pi pi-fw pi-file-pdf",
      disabled: data?.rejection === null,
    },
  ];

  useEffect(() => {
    setUrl(ManagerService.postInvoiceUrl(poNumber, data?.feedBackId));
  }, [data, poNumber]);

  useEffect(() => {
    console.log(isLocal);
    _loadData();
  }, []);

  const _loadData = () => {
    console.log(">>>>>>>>>>>>>", poNumber, invoiceNum);
    setLoading(true);
    ManagerService.getInvoice(poNumber, invoiceNum).then(
      (data) => {
        if (data?.data?.elements.length > 0) {
          setData(data?.data?.elements[0]);
        } else {
          toastRef?.current?.showToast({
            type: "warn",
            title: "Error ",
            message: "List is empty!",
          });
        }
        setLoading(false);
        setIsUploadFile(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };

  const _regectionData = () => {
    setRegectionData({
      invoiceId: data?.invoiceNum,
      purchaseId: poNumber,
      supplierId: supplier,
      rejectionId: data?.rejection?.id,
    });
  };
  const _dgeData = () => {
    setDgeData({
      feedBackId: data?.feedBackId,
      dgeDate: data?.depotDate,
    });
  };
  const _atfData = () => {
    setAtfData({
      feedBackId: data?.feedBackId,
      atfDate: data?.recuperationAtfDate,
    });
  };
  const _bankData = () => {
    setBankData({
      feedBackId: data?.feedBackId,
      bankDate: data?.depotBankDate,
    });
  };
  const _delete_file = (fileId) => {
    setLoading(true);
    ManagerService.deleteInvoices(fileId).then(
      (data) => {
        toastRef?.current?.showToast({
          type: "success",
          title: "Success response",
          message: data.message,
          durable: false,
        });
        setLoading(false);
        _loadData();
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };
  const _return = () => {
    history.push({
      pathname: "/supplierPurchaseInvoices",
      customNameData: poNumber, //data.poNumber,
      customNameDataSave: supplier,
    });
  };
  const onUpload = (e) => {
    let response = e.xhr.response;
    const obj = JSON.parse(response);
    toastRef?.current?.showToast({
      type: "success",
      title: "Success response",
      message: obj.message,
      durable: false,
    });
    _loadData();
  };
  const onError = (e) => {
    var message = "onUpload";
    let response = e?.xhr?.response;
    if (response) {
      const obj = JSON.parse(response);
      message = obj.message;
    }
    toastRef?.current?.showToast({
      type: "error",
      title: "Error Upload",
      message: message,
      durable: false,
    });
  };

  const onBeforeSend = (e) => {
    let token = utils.getTokenFromStorage();
    e.xhr.setRequestHeader("Authorization", "" + token);
  };

  const detailContinent = () => {
    if (regectionData || atfData || dgeData || bankData) {
      return (
        <>
          {regectionData && (
            <RegectionForm
              data={regectionData}
              refreshData={() => {
                setRegectionData(null);
                _loadData();
                toastRef?.current?.showToast({
                  type: "success",
                  title: "Success response",
                  message: "Succès",
                  durable: false,
                });
              }}
              return={() => {
                setRegectionData(null);
              }}
            />
          )}
          {dgeData && (
            <DgeForm
              data={dgeData}
              refreshData={() => {
                setDgeData(null);
                _loadData();
                toastRef?.current?.showToast({
                  type: "success",
                  title: "Success response",
                  message: "Succès",
                  durable: false,
                });
              }}
              return={() => {
                setDgeData(null);
              }}
            />
          )}
          {atfData && (
            <AtfForm
              data={atfData}
              refreshData={() => {
                setAtfData(null);
                _loadData();
                toastRef?.current?.showToast({
                  type: "success",
                  title: "Success response",
                  message: "Succès",
                  durable: false,
                });
              }}
              return={() => {
                setAtfData(null);
              }}
            />
          )}
          {bankData && (
            <BankForm
              data={bankData}
              refreshData={() => {
                setBankData(null);
                _loadData();
                toastRef?.current?.showToast({
                  type: "success",
                  title: "Success response",
                  message: "Succès",
                  durable: false,
                });
              }}
              return={() => {
                setBankData(null);
              }}
            />
          )}
        </>
      );
    } else {
      return (
        <div className="p-fluid">
          <div
            className="p-field"
            style={{ marginBottom: "1.5em", width: "25rem" }}
          >
            <span className="p-float-label p-input-icon-right">
              <i className="pi pi-pencil" style={{ color: "red" }} />
              <InputText
                label="AP status"
                value={data?.rejection?.rejectionName}
                disabled={
                  !utils.isUserPriviligedAction(ACTION_INVOICES.OA_REGECTION)
                }
                onClick={() => _regectionData()}
                icon="pi pi-check"
              />
              <label htmlFor="supplierName" style={{ fontWeight: "bold" }}>
                AP status
              </label>
            </span>
          </div>

          {!isLocal && (
            <div
              className="p-field"
              style={{ marginBottom: "1.5em", width: "25rem" }}
            >
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-pencil" style={{ color: "red" }} />
                <InputText
                  disabled={
                    data?.rejection?.rejectionName === undefined ||
                    !utils.isUserPriviligedAction(ACTION_INVOICES.DGE_DATE)
                  }
                  id="icon"
                  value={utils.parseToLocaleDateFormat(data?.depotDate)}
                  onClick={() => _dgeData()}
                />
                <label htmlFor="supplierName" style={{ fontWeight: "bold" }}>
                  {" "}
                  DGE date
                </label>
              </span>
            </div>
          )}

          {!isLocal && (
            <div
              className="p-field"
              style={{ marginBottom: "1.5em", width: "25rem" }}
            >
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-pencil" style={{ color: "red" }} />
                <InputText
                  disabled={
                    data?.depotDate === undefined ||
                    data?.depotDate === null ||
                    !utils.isUserPriviligedAction(ACTION_INVOICES.ATF_DATE)
                  }
                  id="icon"
                  value={utils.parseToLocaleDateFormat(
                    data?.recuperationAtfDate
                  )}
                  onClick={() => _atfData()}
                />
                <label htmlFor="supplierName" style={{ fontWeight: "bold" }}>
                  {" "}
                  ATF date
                </label>
              </span>
            </div>
          )}

          {!isLocal && (
            <div
              className="p-field"
              style={{ marginBottom: "1.5em", width: "25rem" }}
            >
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-pencil" style={{ color: "red" }} />
                <InputText
                  disabled={
                    data?.recuperationAtfDate === undefined ||
                    data?.recuperationAtfDate === null ||
                    !utils.isUserPriviligedAction(ACTION_INVOICES.BANK_DATE)
                  }
                  id="icon"
                  value={utils.parseToLocaleDateFormat(data?.depotBankDate)}
                  onClick={() => _bankData()}
                />
                <label htmlFor="supplierName" style={{ fontWeight: "bold" }}>
                  Bank date
                </label>
              </span>
            </div>
          )}
        </div>
      );
    }
  };
  const uploadContinent = () => {
    return (
      <div
        className=""
        style={{
          width: "40rem",
          height: "14.8rem",
        }}
      >
        {data?.invoiceFiles?.length > 0 ? (
          <div className="d-flex flex-column">
            {utils.isUserPriviligedAction(ACTION_INVOICES.UPLOAD) && (
              <div style={{ marginBottom: "1rem" }}>
                <Button
                  label="Upload"
                  icon="pi pi-cloud-upload"
                  disabled={data?.invoiceFiles.length === 2}
                  onClick={() => {
                    setIsUploadFile(true);
                  }}
                />
              </div>
            )}
            {data?.invoiceFiles.map((invoice) => (
              <div className="p-fileupload-row">
                <div className="p-d-flex p-ai-center p-flex-wrap">
                  <div
                    className="p-d-flex p-ai-center mb-2"
                    style={{ width: "60%" }}
                  >
                    <i
                      className="pi pi-file-pdf"
                      style={{
                        fontSize: "2rem",
                        color: "rgb(232, 29, 38)",
                      }}
                    />
                    <a href={invoice.invoicePath} target="_blank">
                      <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {invoice.fileName.replace(".pdf", "")}
                        <small>
                          {utils.parseToLocaleDateFormat(invoice.insertionDate)}
                        </small>
                      </span>
                    </a>
                  </div>
                  <span className="p-tag p-component p-tag-warning px-3 py-2">
                    <span className="p-tag-value">
                      {`${invoice.fileSize} KB`}
                    </span>
                    <span></span>
                  </span>
                  <button
                    type="button"
                    onClick={() => {
                      _delete_file(invoice.id);
                    }}
                    className="p-button p-component p-button-outlined p-button-rounded p-button-danger ml-auto p-button-icon-only mb-2"
                  >
                    <span className="p-button-icon p-c pi pi-times"></span>
                    <span className="p-button-label p-c">&nbsp;</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column w-100 h-100">
            {utils.isUserPriviligedAction(ACTION_INVOICES.UPLOAD) && (
              <Button
                label="Upload"
                icon="pi pi-cloud-upload"
                disabled={data?.invoiceFiles.length === 2}
                onClick={() => {
                  setIsUploadFile(true);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <TabMenu
        model={items}
        activeIndex={activeIndex}
        onTabChange={(e) => {
          setActiveIndex(e.index);
        }}
        style={{ marginBottom: "2rem" }}
      />
      {loading ? (
        <div className="my-5">
          <Skeleton height="11rem" width="25rem" />
        </div>
      ) : (
        <div className="form-demo">
          <div className="p-d-flex p-jc-center f-d-column">
            {activeIndex == 0 && detailContinent()}
            {activeIndex == 1 && uploadContinent()}
            <Popup
              title={"Upload Files"}
              visible={isUploadFile}
              maximizable
              className="custom-dialog-small-large"
              content={
                <UploadForm
                  name="invoice"
                  url={url}
                  onUpload={(e) => {
                    onUpload(e);
                  }}
                  onError={(e) => {
                    onError(e);
                  }}
                  onBeforeSend={(e) => {
                    onBeforeSend(e);
                  }}
                />
              }
              onClose={() => {
                setIsUploadFile(false);
              }}
            />
          </div>
        </div>
      )}
      <ToastNotification ref={toastRef} />
    </>
  );
};
