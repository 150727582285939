import React, { useRef, useState, useEffect } from "react";
import { ToastNotification } from "../../shared";
import { ManagerService } from "../../../api";
import { AddUserForm } from "./add-user-form";

export const AddUser = (props) => {
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();

  useEffect(() => {}, []);

  const _submit = (data) => {
    setLoading(true);
    ManagerService.postUser({
      username: data.username,
      password: data.password,
      supplierId: data.supplierId,
      email: data.email,
    }).then(
      (datac) => {
        toastRef?.current?.showToast({
          type: "success",
          title: "Success response",
          message: datac.message,
          durable: false,
        });
        setLoading(false);
        props.onClose();
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };

  return (
    <>
      <AddUserForm _submit={_submit} loading={loading} />
      <ToastNotification ref={toastRef} />
    </>
  );
};
