import { HttpService } from ".";
import utils from "../../src/utils";
const API_MANAGER = process.env.REACT_APP_API_URL_MANAGER;

class ManagerService {
  //GET SITES WITH LAZY LOADING

  purchaseOrders = (pageIndex, pageSize, supplierId, filterParams) => {
    const param = utils.getRequestParams(filterParams);
    return HttpService.get(
      API_MANAGER +
        `/api/v1/purchase-orders?pageIndex=` +
        pageIndex +
        `&pageSize=` +
        pageSize +
        "&supplierId=" +
        supplierId +
        param
    );
  };
  getPurchaseInvoicesMan = (poNumber, pageIndex, pageSize, filterParams) => {
    const param = utils.getRequestParams(filterParams);
    return HttpService.get(
      API_MANAGER +
        "/api/v1/purchase-invoices?pageIndex=" +
        pageIndex +
        `&pageSize=` +
        pageSize +
        "&poNumber=" +
        poNumber +
        param
    );
  };
  getInvoice = (poNumber, invoiceNum) => {
    return HttpService.get(
      API_MANAGER +
        "/api/v1/purchase-invoices?pageIndex=0&pageSize=2&poNumber=" +
        poNumber +
        "&invoiceNum=" +
        invoiceNum
    );
  };

  getSupplier = () => {
    return HttpService.get(API_MANAGER + `/api/v1/supplier-info`);
  };

  pathSupplier = (data) => {
    return HttpService.patch(API_MANAGER + "/api/v1/", data);
  };
  getListSupplier = (pageIndex, pageSize, filter) => {
    const param = utils.getRequestParams(filter);
    return HttpService.get(
      API_MANAGER +
        `/api/v1/suppliers?pageIndex=` +
        pageIndex +
        `&pageSize=` +
        pageSize +
        param
    );
  };

  postUser = (data) => {
    return HttpService.post(API_MANAGER + "/api/v1/user", data);
  };

  getListRejections = () => {
    return HttpService.get(API_MANAGER + `/api/v1/rejections`);
  };

  postInvoiceUrl = (purchaseOrder, feedBackId) => {
    return (
      process.env.REACT_APP_API_URL +
      API_MANAGER +
      "/api/v1/upload-invoice?purchaseOrder=" +
      purchaseOrder +
      "&feedBackId=" +
      feedBackId
    );
  };
  postRejection = (data) => {
    return HttpService.post(API_MANAGER + "/api/v1/rejection", data);
  };

  deleteRejection = (id) => {
    return HttpService.delete(API_MANAGER + "/api/v1/rejection/" + id);
  };

  postOaRejection = (data) => {
    return HttpService.post(API_MANAGER + "/api/v1/oa-rejection", data);
  };
  pathDgeDate = (data, feedBackId) => {
    var date = feedBackId + "?requested-date=" + utils.toTimestamp(data);
    return HttpService.patchSec(API_MANAGER + "/api/v1/dge-date/" + date);
  };
  pathAtfDate = (data, feedBackId) => {
    var date = feedBackId + "?requested-date=" + utils.toTimestamp(data);
    return HttpService.patchSec(API_MANAGER + "/api/v1/atf-date/" + date);
  };
  pathBankDate = (data, feedBackId) => {
    var date = feedBackId + "?requested-date=" + utils.toTimestamp(data);
    return HttpService.patchSec(API_MANAGER + "/api/v1/bank-date/" + date);
  };
  deleteInvoices = (feedBackId) => {
    return HttpService.delete(
      API_MANAGER + "/api/v1/invoice-file/" + feedBackId
    );
  };
}
export default new ManagerService();
