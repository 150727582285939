import React, { useRef, useState, useEffect } from "react";
import { ToastNotification, InpuText, DropdownInput } from "../../shared";
import { useFormik } from "formik";
import { Skeleton } from "primereact/skeleton";
import { ReportingService, ManagerService } from "../../../api";
import { Button } from "primereact/button";
import reportingService from "../../../api/reporting-service";

import utils from "../../../utils";

export const ImportData = (props) => {
  const toastRef = useRef();
  let loadLazyTimeout = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [reset, setReset] = useState(false);
  const [lazyItems, setLazyItems] = useState([]);
  const [index, setIndex] = useState(0);
  const [size, setSize] = useState(30);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [data, setData] = useState({
    reportType: "",
    supplierId: utils.roles.get("ROLE_SUPPLIER") ? utils.getSupplierID() : "",
    startDate: utils.parseToLocaleDateFormat(new Date()),
    endDate: utils.parseToLocaleDateFormat(new Date()),
  });

  const reportType = [
    { id: 1, value: "orders" },
    { id: 2, value: "invoices" },
  ];

  useEffect(() => {
    formik.resetForm(data);
    setReset(false);
    !utils.roles.get("ROLE_SUPPLIER") && _loadData(index, size, true);
  }, [reset]);

  const _loadData = (index, size, isFirst) => {
    setLoading(true);
    if (isFirst) setLoadingFirst(true);
    ManagerService.getListSupplier(index, size, "").then(
      (datac) => {
        const _lazyItems = [...lazyItems];
        _lazyItems.push.apply(_lazyItems, datac.data.elements);
        setLazyItems(_lazyItems);
        setLoading(false);
        if (isFirst) setLoadingFirst(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
        if (isFirst) setLoadingFirst(false);
      }
    );
  };

  const getReport = (data) => {
    if (data.reportType === "orders") {
      setSubmitLoading(true);
      reportingService
        .getPurchaseOrdersReport(data.startDate, data.endDate, data.supplierId)
        .then(
          (response) => {
            let filename = "orders";
            filename = filename + "-" + data.startDate + "-" + data.endDate;
            setSubmitLoading(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");

            link.setAttribute("download", filename + ".xlsx");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            toastRef?.current?.showToast({
              type: "error",
              title: "Error response",
              message: error,
            });
            setSubmitLoading(false);
          }
        );
    } else if (data.reportType === "invoices") {
      setSubmitLoading(true);
      reportingService
        .getInvoicesReport(data.startDate, data.endDate, data.supplierId)
        .then(
          (response) => {
            let filename = "invoices";
            filename = filename + "-" + data.startDate + "-" + data.endDate;
            setSubmitLoading(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");

            link.setAttribute("download", filename + ".xlsx");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            toastRef?.current?.showToast({
              type: "error",
              title: "Error response",
              message: error,
            });
            setSubmitLoading(false);
          }
        );
    }
  };

  const _filter = (index, size, filter) => {
    const param = { supplierName: filter };
    setLoading(true);
    ManagerService.getListSupplier(index, size, param).then(
      (datac) => {
        setLazyItems(datac.data.elements);
        setLoading(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };
  const formik = useFormik({
    initialValues: data,
    validate: (data) => {
      let errors = {};
      if (!data.reportType) {
        errors.reportType = "Report type is required.";
      }
      if (!data.supplierId && !utils.roles.get("ROLE_SUPPLIER")) {
        errors.supplierId = "Supplier is required.";
      }

      if (!data.startDate) {
        errors.startDate = "Start date confirmation is required.";
      }
      if (!data.endDate) {
        errors.endDate = "End date confirmation is required.";
      }

      return errors;
    },
    onSubmit: (data) => {
      getReport(data);
      //   props._submit(data);
      //   props._loadData();
      //   setReset(true);
      //   setShowMessage(true);
    },
  });
  const _reset = () => {
    setReset(true);
  };
  const onFilter = (event) => {
    if (event.filter !== "") {
      setIndex(0);
      _filter(0, size, event.filter);
    } else {
      setIndex(0);
      setLazyItems([]);
    }
  };

  const onLazyLoad = (event) => {
    const { first, last, filter } = event;
    if (event.filter === "") {
      setLazyLoading(true);
      if (loadLazyTimeout) {
        clearTimeout(loadLazyTimeout);
      }
      if (index == 0 && last == 0) {
        _loadData(index, size, false);
      } else if (last == lazyItems.length) {
        _loadData(index, size, false);
        setIndex(index + 1);
      }
      //imitate delay of a backend call
      loadLazyTimeout = setTimeout(() => {
        setLazyLoading(false);
      }, Math.random() * 1000 + 250);
    }
  };

  const itemOptionTemplate = (option) => {
    return (
      <div className="p-filter-dropd-cs">
        <div>{option.supplierName}</div>
      </div>
    );
  };

  const isFormFieldValid = (name) => {
    return !!(formik.touched[name] && formik.errors[name]);
  };

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      {loadingFirst || reset ? (
        <Skeleton width="25rem" height="15rem"></Skeleton>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-demo">
              <div className="p-d-flex p-jc-center">
                <div className="p-fluid">
                  <DropdownInput
                    id="reportType"
                    name="reportType"
                    label="Report type"
                    value={formik.values.reportType}
                    onChange={formik.handleChange}
                    options={reportType}
                    optionLabel="value"
                    required
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />
                  {!utils.isUserPriviliged(
                    utils.roles.get("ROLE_SUPPLIER")
                  ) && (
                    <DropdownInput
                      id="supplierId"
                      name="supplierId"
                      label="Supplier"
                      value={formik.values.supplierId}
                      onChange={formik.handleChange}
                      iconRight="pi pi-users"
                      options={lazyItems}
                      optionLabel="supplierName"
                      itemTemplate={itemOptionTemplate}
                      virtualScrollerOptions={{
                        lazy: true,
                        onLazyLoad: onLazyLoad,
                        itemSize: 38,
                        showLoader: true,
                        loading: lazyLoading,
                        delay: 250,
                        loadingTemplate: (options) => {
                          return (
                            <div className="flex align-items-center p-2">
                              <Skeleton
                                width={options.even ? "60%" : "50%"}
                                height="1rem"
                              />
                            </div>
                          );
                        },
                      }}
                      optionValue="id"
                      filter={true}
                      onFilter={onFilter}
                      getFormErrorMessage={getFormErrorMessage}
                      isFormFieldValid={isFormFieldValid}
                    />
                  )}

                  <InpuText
                    className="mt-2"
                    type="date"
                    id="startDate"
                    name="startDate"
                    label="Start date"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />

                  <InpuText
                    className="mt-2"
                    id="endDate"
                    name="endDate"
                    label="End date"
                    type="date"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />

                  <Button
                    className="mt-3"
                    type="submit"
                    label="Submit"
                    loading={submitLoading}
                  />
                </div>
              </div>
            </div>
          </form>
        </>
      )}
      <ToastNotification ref={toastRef} />
    </>
  );
};
