import React, { useRef, useState, useEffect } from "react";
import { CustomDataTable, Popup, ToastNotification } from "../../shared";
import { UserService } from "../../../api";
import OutilsUsers from "./outils-users";
import { useHistory } from "react-router";
import { AddUser } from "./add-user";
import { InputText } from "primereact/inputtext";

export const ListUsers = (props) => {
  const [loading, setLoading] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState(OutilsUsers._getLazyParams());
  const [filterParams, setFilterParams] = useState();
  const [data, setData] = useState();
  const toastRef = useRef();
  const dt = useRef(null);
  const history = useHistory();

  useEffect(() => {
    loadLazyData();
  }, []);

  function _handleChange(e) {
    setFilterParams((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  const loadLazyData = () => {
    setLoading(true);
    UserService.getListUsers().then(
      (data) => {
        setData(OutilsUsers._map(data.data, deleteUser));
        setTotalRecords(data?.data?.length);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
      }
    );
  };

  const activeBodyTemplate = (rowData) => {
    return (
      <span className={`active-badge status-${rowData.active.toLowerCase()}`}>
        {rowData.active}
      </span>
    );
  };

  const onPage = (event) => {
    setLazyParams(event);
  };
  const _add = () => {
    setAddNew(true);
  };
  const _hide = () => {
    setAddNew(false);
    loadLazyData();
  };

  const deleteUser = (id) => {
    setLoading(true);
    UserService.deleteUser(id).then(
      (data) => {
        setLoading(false);
        toastRef?.current?.showToast({
          type: "success",
          title: "Success response",
          message: data.message,
          durable: false,
        });
        loadLazyData();
      },
      (error) => {
        setLoading(false);
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
      }
    );
  };
  const _filterElement = (field) => {
    switch (field) {
      case "action":
        return <></>;
      default:
        return (
          <div className="col filter-element">
            <InputText
              id={field}
              className="p-filter-tab-cs"
              name={field}
              onChange={() => {}}
            />
          </div>
        );
    }
  };

  return (
    <>
      <CustomDataTable
        title="Users"
        icon="pi bi bi-person-lines-fill"
        data={data}
        totalRecords={totalRecords}
        loading={loading}
        ref={dt}
        onPage={onPage}
        first={lazyParams.first}
        page={lazyParams.page}
        rows={lazyParams.rows}
        filterElement={_filterElement}
        columns={OutilsUsers._getColumns(activeBodyTemplate)}
        new={_add}
        className="table-large"
      />
      <Popup
        title={"Add new user "}
        visible={addNew}
        maximizable
        className="custom-dialog-small"
        content={<AddUser onClose={_hide} />}
        onClose={_hide}
      ></Popup>
      <ToastNotification ref={toastRef} />
    </>
  );
};
