import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center my-2">
          <h1>
            <i className="bi bi-exclamation-triangle" />
          </h1>
          <h3>Something went wrong !</h3>
        </div>
      );
    }

    return this.props.children;
  }
}
