import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { Toast } from "primereact/toast";

export const ToastNotification = forwardRef((props, ref) => {

    const customToast = useRef(null);

    useImperativeHandle(ref,
        () => ({
            showToast({type, title, message, durable}) {
                customToast?.current?.show({ severity: type, summary: title, detail: message, life: 6000, sticky: durable });
            },
            clearToast() {
                customToast && customToast.current.clear();
            }
        })
    );

    return (
        <Toast ref={customToast}  position="top-right" />
    );
});