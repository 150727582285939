import React, { useRef, useState, useEffect } from "react";
import { ToastNotification, InpuText, Spinner } from "../../../shared";
import { useFormik } from "formik";
import { ManagerService } from "../../../../api";
import utils from "../../../../utils";
import { Button } from "primereact/button";
import {ActionReturn} from "../../commun/action-return";

export const DgeForm = (props) => {
  const toastRef = useRef();
  const [showMessage, setShowMessage] = useState(false);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sumbitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState(props.data);

  useEffect(() => {}, []);

  useEffect(() => {
    formik.resetForm(data);
  }, []);

  const formik = useFormik({
    initialValues: data,
    validate: (data) => {
      let errors = {};
      if (!data.dgeDate) {
        errors.dgeDate = "DGE date is required.";
      }
      return errors;
    },
    onSubmit: (data) => {
      _handleSubmit(data);
      setShowMessage(true);
    },
  });
  const _handleSubmit = (data) => {
    setSubmitLoading(true);
    ManagerService.pathDgeDate(data.dgeDate, data.feedBackId)
      .then(
        (datac) => {
          toastRef?.current?.showToast({
            type: "success",
            title: "Success response",
            message: datac.message,
            durable: false,
          });
          setSubmitLoading(false);
          props.refreshData();
        },
        (error) => {
          toastRef?.current?.showToast({
            type: "error",
            title: "Error response",
            message: error,
          });
          setSubmitLoading(false);
        }
      )
      .finally(() => {});
  };

  const isFormFieldValid = (name) => {
    return !!(formik.touched[name] && formik.errors[name]);
  };

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      {loading && (
        <div className="my-5">
          <Spinner label="Processing..." />
        </div>
      )}
      {!loading && (
        <>
          {!reset && (
            <>
              <form onSubmit={formik.handleSubmit}>
                <div className=" container-frm-sb  justify-content-center">
                  <InpuText
                    id="dgeDate"
                    name="dgeDate"
                    type="Date"
                    label="DGE date"
                    value={utils.formatDateSu(formik?.values?.dgeDate)}
                    onChange={formik.handleChange}
                    required
                    autoFocus
                    getFormErrorMessage={getFormErrorMessage}
                    isFormFieldValid={isFormFieldValid}
                  />

                  <div className="row mt-3">
                    <ActionReturn onClick={props.return}/>
                    <Button
                        type="submit"
                        label="Submit"
                        style={{width: "90%",marginLeft:"1rem"}}
                        loading={sumbitLoading}
                    />
                  </div>
                </div>
              </form>
            </>
          )}
        </>
      )}
      <ToastNotification ref={toastRef} />
    </>
  );
};
