import { HttpService } from ".";
import utils from "../utils";

const API_SUPPLIER = process.env.REACT_APP_API_URL_SUPPLIER;
class SuppliersService {
  //GET SITES WITH LAZY LOADING

  purchaseOrders = (pageIndex, pageSize, filterParams) => {
    const param = utils.getRequestParams(filterParams);
    return HttpService.get(
      API_SUPPLIER +
        `/api/v1/purchase-orders?pageIndex=` +
        pageIndex +
        `&pageSize=` +
        pageSize +
        param
    );
  };
  getPurchaseInvoices = (poNumber, pageIndex, pageSize, filterParams) => {
    const param = utils.getRequestParams(filterParams);
    return HttpService.get(
      API_SUPPLIER +
        `/api/v1/purchase-invoices?pageIndex=` +
        pageIndex +
        `&pageSize=` +
        pageSize +
        "&poNumber=" +
        poNumber +
        param
    );
  };

  getSupplier = () => {
    return HttpService.get(API_SUPPLIER + `/api/v1/supplier-info`);
  };
  pathSupplier = (data) => {
    return HttpService.patch(API_SUPPLIER + "/api/v1/", data);
  };
}
export default new SuppliersService();
