import React from 'react';
import Messages from '../../utils/custom-messages';
import iconLocation from "../../assets/image-recents/role.png";

export const AccessDenied = () => {
    return (
        <div className='alert  access-denied-content mx-auto'>
            {<img src={iconLocation} height={200} alt="img"/>}<br />
            <span>{Messages.NO_ACCESS_PRIVILEGES_MESSAGE}</span>
        </div>
    );
}