import React, { useRef, useState, useEffect } from "react";
import { CustomDataTable, Popup, ToastNotification } from "../../shared";
import Outils from "./outilsPurchageOrders";
import { ManagerService } from "../../../api";
import { useHistory } from "react-router";
import { InputText } from "primereact/inputtext";
import { SupplierPurchaseInvoicesUpdate } from "./suppliers-purchase-invoices-update";
import { Calendar } from "primereact/calendar";
import { ActionClear } from "../commun/action-clear";

export const SupplierPurchaseInvoices = (props) => {
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState();
  const [dataDetails, setDataDetails] = useState(null);
  const [dataUpdate, setDataUpdate] = useState({ data: {}, visibility: false });
  const [lazyParams, setLazyParams] = useState(Outils._getLazyParams());
  const [param, setParam] = useState(props.location.customNameData);
  const [isLocal, setIsLocal] = useState(props.location.isLocal);
  const [params, setParams] = useState(props.location.customNameDataSave);
  const [filterParams, setFilterParams] = useState(
    Outils._getParamFilterInvoices()
  );
  const [clear, setClear] = useState(true);
  const toastRef = useRef();
  const dt = useRef(null);
  const history = useHistory();

  console.log(dataUpdate);

  useEffect(() => {
    if (param) {
      _loadData();
    } else {
      history.push({
        pathname: "/suppliersPurchaseOrders",
      });
    }
  }, [lazyParams, filterParams]);

  const onPage = (event) => {
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const _loadData = () => {
    setLoading(true);
    ManagerService.getPurchaseInvoicesMan(
      param,
      lazyParams.page,
      lazyParams.rows,
      filterParams
    ).then(
      (data) => {
        setData(
          Outils._mapInvoices(
            data.data.elements,
            _getActionInvoicesDetail,
            _getActionInvoicesUpdate
          )
        );
        setTotalRecords(data?.data?.totalElement);
        setLoading(false);
      },
      (error) => {
        toastRef?.current?.showToast({
          type: "error",
          title: "Error response",
          message: error,
        });
        setLoading(false);
      }
    );
  };
  const _getActionInvoicesDetail = (data) => {
    setDataDetails(data);
  };
  const _getActionInvoicesUpdate = (data) => {
    console.log("Helloo", data);
    setDataUpdate({ data: data, visibility: true });
    //  console.log(dataUpdate);
  };
  const _getfilterContainer = () => {
    return (
      <div className="justify-content-center">
        <div className="row">
          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                id="description"
                className="p-filter-tab-cs"
                name="description"
                value={filterParams.description}
                onChange={_handleChange}
              />
              <label htmlFor="description">Description</label>
            </span>
          </div>
          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                id="invoiceNum"
                className="p-filter-tab-cs"
                name="invoiceNum"
                value={filterParams.invoiceNum}
                onChange={_handleChange}
              />
              <label htmlFor="invoiceNum">Invoice number</label>
            </span>
          </div>

          <div className="col filter-element">
            <span className="p-float-label">
              <Calendar
                id="invoiceDate"
                className="p-filter-tab-cs"
                name="invoiceDate"
                monthNavigator
                yearNavigator
                type="Date"
                yearRange="2010:2200"
                value={filterParams.invoiceDate}
                onChange={_handleDateChange}
              />
              <label htmlFor="invoiceDate">Date</label>
            </span>
          </div>

          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                id="invoiceCurrencyCode"
                className="p-filter-tab-cs"
                name="invoiceCurrencyCode"
                value={filterParams.invoiceCurrencyCode}
                onChange={_handleChange}
              />
              <label htmlFor="invoiceCurrencyCode">Currency Code</label>
            </span>
          </div>

          <div className="col filter-element">
            <span className="p-float-label">
              <InputText
                id="invoiceStatus"
                className="p-filter-tab-cs"
                name="invoiceStatus"
                value={filterParams.invoiceStatus}
                onChange={_handleChange}
              />
              <label htmlFor="invoiceStatus">Status</label>
            </span>
          </div>
          <div className="filter-element-btn mt-1">
            <ActionClear onClick={_clearFilter} />
          </div>
        </div>
      </div>
    );
  };
  function _handleChange(e) {
    setFilterParams((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  function _handleDateChange(e) {
    setFilterParams((prevState) => {
      return {
        ...prevState,
        [e.target.name]: new Date(e.target.value).getTime(),
      };
    });
  }
  const _clearFilter = () => {
    setFilterParams(Outils._getParamFilterInvoices());
    setClear(true);
  };
  const _applyFilter = () => {
    setClear(false);
  };
  const _return = () => {
    history.push({
      pathname: "/suppliersPurchaseOrders",
      customNameData: params,
    });
  };
  return (
    <>
      <CustomDataTable
        title={
          "Purchase invoices, Supplier ID: " + params + ", PO Number: " + param
        }
        icon="pi bi bi-person-lines-fill"
        data={data}
        lazy={true}
        totalRecords={totalRecords}
        loading={loading}
        ref={dt}
        onPage={onPage}
        onSort={onSort}
        first={lazyParams.first}
        page={lazyParams.page}
        rows={lazyParams.rows}
        clear={clear}
        return={_return}
        filterContainer={_getfilterContainer()}
        columns={Outils._getColumnsInvoices()}
        className="table-large"
      />

      <Popup
        title={`Update Invoice: ${dataUpdate?.data?.invoiceNum}`}
        visible={dataUpdate?.visibility}
        maximizable
        className="custom-dialog"
        style={{ width: "340rem" }}
        content={
          <SupplierPurchaseInvoicesUpdate
            data={{
              invoiceNum: dataUpdate?.data?.invoiceNum,
              poNumber: param,
              supplier: params,
              isLocal: isLocal,
            }}
          />
        }
        onClose={() => {
          setDataUpdate({ data: null, visiblity: false });
        }}
      ></Popup>
      <ToastNotification ref={toastRef} />
    </>
  );
};
