import React from "react";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";

export const PasswordInputText = (props) => {
  return (
    <div className="p-float-inpuText">
      <span className={"p-float-label p-input-icon-right"}>
        <i className={props.iconRight} />
        <Password
          autoComplete={props.autoComplete}
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          name={props.name}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange}
          autoCapitalize={false}
          feedback={false}
          autoFocus={false}
          toggleMask={props.toggleMask}
          className={
            props.isFormFieldValid(props.name)
              ? "p-error p-inputtext-size "
              : "p-valid p-inputtext-size"
          }
        />
        <label htmlFor={props.id}>
          {props.label}
          {props.required ? "*" : ""}
        </label>
      </span>
      <div className="p-float-inpuText-erreur">
        {props.getFormErrorMessage(props.name)}
      </div>
    </div>
  );
};
